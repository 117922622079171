import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import NavBar from '@/common/components/NavBar';
// import ErrorBoundary from '@/common/components/ErrorBoundary';
import Locations from '@/features/locations';
import Features from '@/features/features';
import Managers from '@/features/managers';
import LocationGroups from '@/features/location-groups';
import Messages from '@/features/messages';
import Surveys from '@/features/surveys';
import PushNotifications from '@/features/push-notifications';
import Franchise from '@/features/franchise';
import Login from '@/features/login';
import Reports from '@/features/reports';

/* eslint-disable object-curly-newline */
const leftItems = [
  { content: 'Locations', key: 'locations', to: '/locations' },
  { content: 'Features', key: 'features', to: '/features' },
  { content: 'Location Groups', key: 'locationGroups', to: '/location-groups' },
  { content: 'Messages', key: 'messages', to: '/messages' },
  { content: 'Push Notifications', key: 'pushNotifications', to: '/push-notifications' },
  { content: 'Surveys', key: 'surveys', to: '/surveys' },
  { content: 'Managers', key: 'managers', to: '/managers' },
  { content: 'General', key: 'franchise', to: '/franchise' },
  { content: 'Reports', key: 'reports', to: '/reports' },
];
const rightItems = [{ content: 'Logout', key: 'logout', to: '/logout' }];

const Auth = ({ user }) => {
  if (!user || user.expired) {
    return <Login />;
  }

  return (
    <NavBar leftItems={leftItems} rightItems={rightItems}>
      {/* <ErrorBoundary> */}
      <Switch>
        <Route path="/locations" component={Locations} />
        <Route path="/features" component={Features} />
        <Route path="/location-groups" component={LocationGroups} />
        <Route path="/messages" component={Messages} />
        <Route path="/push-notifications" component={PushNotifications} />
        <Route path="/surveys" component={Surveys} />
        <Route path="/managers" component={Managers} />
        <Route path="/franchise" component={Franchise} />
        <Route path="/reports" component={Reports} />
        <Redirect to="/locations" push />
      </Switch>
      {/* </ErrorBoundary> */}
    </NavBar>
  );
};

Auth.propTypes = {
  user: PropTypes.shape({
    expired: PropTypes.bool,
  }),
};

Auth.defaultProps = {
  user: {},
};

const mapStateToProps = state => ({
  user: state.oidc.user,
});

export default connect(mapStateToProps)(Auth);
