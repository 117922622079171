import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Icon, Input, Menu } from 'semantic-ui-react/dist/es';
import { debounce } from 'lodash-es';

import FeaturesList from '@/features/features/FeaturesList';

const FeaturesMenu = ({ onSearchChange }) => (
  <Menu stackable borderless secondary>
    <Menu.Item>
      <Button as={Link} to="/features/create" icon primary size="mini" labelPosition="left">
        <Icon name="add" />
        Create Feature
      </Button>
    </Menu.Item>
    <Menu.Item position="right">
      <Input
        className="icon"
        icon="search"
        placeholder="Search..."
        onChange={onSearchChange}
      />
    </Menu.Item>
  </Menu>
);

FeaturesMenu.propTypes = {
  onSearchChange: PropTypes.func.isRequired,
};

class FeaturesPage extends React.Component {
  state = {
    keyword: '',
  }

  handleSearchChange = debounce((e, data) => {
    this.setState({
      keyword: data.value,
    });
  }, 500);

  render() {
    return (
      <React.Fragment>
        <FeaturesMenu onSearchChange={this.handleSearchChange} />
        <FeaturesList keyword={this.state.keyword} />
      </React.Fragment>
    );
  }
}

export default FeaturesPage;
