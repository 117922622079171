import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Yup from 'yup';
import { Button, Divider, Form, Header, Message, Segment } from 'semantic-ui-react/dist/es';

const FranchiseEditForm = ({
  facebookUsername,
  disclaimer,
  onSubmit,
  isSubmitting,
}) => (
  <Segment clearing>
    <Formik
      enableReinitialize
      initialValues={{
        facebookUsername: facebookUsername || '',
        disclaimer: disclaimer || '',
      }}
      validationSchema={Yup.object().shape({
        facebookUsername: Yup.string()
          .required('Facebook username is required!')
          .max(100, 'Facebook username must not exceed 100 characters!'),
        disclaimer: Yup.string().required('Disclaimer is required!'),
      })}
      onSubmit={values => onSubmit(values)}
      render={({
        values, errors, touched, handleChange, handleBlur, handleSubmit, isValid,
      }) => (
        <React.Fragment>
          <Header size="medium">General</Header>

          <Form onSubmit={handleSubmit} error={!!errors} loading={isSubmitting}>
            <Form.TextArea
              name="disclaimer"
              label="Disclaimer"
              placeholder="Disclaimer"
              value={values.disclaimer}
              error={touched.disclaimer && !!errors.disclaimer}
              onBlur={handleBlur}
              onChange={handleChange}
              required
            />

            {
              errors && errors.disclaimer && touched.disclaimer &&
                <Message negative content={errors.disclaimer} size="mini" />
            }

            <Form.Input
              name="facebookUsername"
              label="Facebook Username"
              placeholder="Facebook Username"
              value={values.facebookUsername}
              error={touched.facebookUsername && !!errors.facebookUsername}
              onBlur={handleBlur}
              onChange={handleChange}
              required
            />

            {
              errors && errors.facebookUsername && touched.facebookUsername &&
                <Message negative content={errors.facebookUsername} size="mini" />
            }

            {
              errors && errors.amountDonated && touched.amountDonated &&
                <Message negative content={errors.amountDonated} size="mini" />
            }

            <Divider />

            <Button color="teal" disabled={isSubmitting || !isValid} floated="right">
              Save
            </Button>
          </Form>
        </React.Fragment>
      )}
    />
  </Segment>
);

FranchiseEditForm.propTypes = {
  facebookUsername: PropTypes.string,
  disclaimer: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

FranchiseEditForm.defaultProps = {
  facebookUsername: '',
  disclaimer: '',
};

export default FranchiseEditForm;
