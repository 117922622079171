import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { history } from '@/app/store';
import { Grid } from 'semantic-ui-react/dist/es';

import api from '@/common/api';
import StatusToggle from '@/common/components/StatusToggle';
import ImageUpload from '@/common/components/ImageUpload';
import MessageEditForm from '@/features/messages/MessageEditForm';
import './MessageEditPage.css';

// const ADD_NOTFICIATION_ERROR = 'Error adding push notification!';
// const ADD_NOTFICIATION_SUCCESS = 'Successfully added push notification!';
const GET_ERROR = 'Error getting message!';
const UPDATE_SUCCESS = 'Successfully updated message!';
const UPDATE_ERROR = 'Error updating message!';

// const getUpdateMessage = entityName => `Successfully updated ${entityName}!`;
// const getErrorMessage = entityName => `Error updating ${entityName}!`;

class MessageEditPage extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  };

  state = {
    loading: true,
    message: {},
    messageDeliveryTypes: [],
    messageTypes: [],
    dynamicMessageTypes: [],
    reoccurringMessageTypes: [],
    locations: [],
  };

  async componentDidMount() {
    const { id } = this.props.match.params;

    try {
      let message = {};
      if (id) {
        ({ data: message } = await api.get(`/api/message/${id}`));
      }

      const { data: messageDeliveryTypes } = await api.get('/api/messagedeliverytype');
      const { data: messageTypes } = await api.get('/api/messagetype');
      const { data: dynamicMessageTypes } = await api.get('api/dynamicmessagetype');
      const { data: reoccurringMessageTypes } = await api.get('api/reoccurringmessagetype');
      const { data: locations } = await api.get('api/location');

      this.setState({
        loading: false,
        message,
        messageDeliveryTypes,
        messageTypes,
        dynamicMessageTypes,
        reoccurringMessageTypes,
        locations,
      });
    } catch (e) {
      toast.error(GET_ERROR);
    }
  }

  handleSubmit = (message) => {
    const { id } = this.props.match.params;

    this.setState({ loading: true }, async () => {
      try {
        const promise = id ?
          api.put(`/api/message/${id}`, message)
          : api.post('/api/message', message);

        const response = await promise;
        toast.success(UPDATE_SUCCESS);
        if (!id) {
          history.push(`/messages/${response.data.id}`);
        }
      } catch (e) {
        toast.error(UPDATE_ERROR);
      }

      this.setState({ loading: false });
    });
  };

  handleStatusToggle = () => {
    const { id } = this.props.match.params;
    const url = `/api/message/${id}/status`;

    this.setState({ loading: true }, async () => {
      const enabled = !this.state.message.enabled;
      const promise = enabled ? api.put(url) : api.delete(url);
      try {
        await promise;
        toast.success(UPDATE_SUCCESS);
        this.setState(prevState => ({
          message: {
            ...prevState.message,
            enabled,
          },
        }));
      } catch (e) {
        toast.error(UPDATE_ERROR);
      }

      this.setState({ loading: false });
    });
  };

  handleUpload = async (files) => {
    if (!(files && files.length)) {
      toast.error('Unable to upload selected file. Is it in the correct format?');
      return;
    }

    this.setState({ loading: true }, async () => {
      const { id } = this.props.match.params;
      const file = files[0];
      const form = new FormData();
      form.append('file', file, file.name);

      try {
        const { data } = await api.put(`/api/message/${id}/image`, form, {
          headers: { 'content-type': 'multipart/form-data' },
        });

        toast.success(UPDATE_SUCCESS);
        this.setState(prevState => ({
          message: {
            ...prevState.message,
            imageName: data,
          },
        }));
      } catch (e) {
        toast.error(UPDATE_ERROR);
      }

      this.setState({ loading: false });
    });
  };

  render() {
    const { id } = this.props.match.params;
    return (
      <Grid stackable columns="equal">
        <Grid.Column>
          <MessageEditForm
            {...this.state.message}
            messageDeliveryTypes={this.state.messageDeliveryTypes}
            messageTypes={this.state.messageTypes}
            dynamicMessageTypes={this.state.dynamicMessageTypes}
            reoccurringMessageTypes={this.state.reoccurringMessageTypes}
            locations={this.state.locations}
            onSubmit={this.handleSubmit}
            isSubmitting={this.state.loading}
          />
        </Grid.Column>
        { id && (
          <Grid.Column width={4}>
            <StatusToggle
              enabled={this.state.message.enabled}
              loading={this.state.loading}
              onClick={this.handleStatusToggle}
            />
            <ImageUpload
              onUpload={this.handleUpload}
              loading={this.state.loading}
              image={this.state.message.imageName}
            />
          </Grid.Column>
        )}
      </Grid>
    );
  }
}

export default withRouter(MessageEditPage);
