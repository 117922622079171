import React from 'react';
import PropTypes from 'prop-types';
import { Table, Loader, Pagination } from 'semantic-ui-react/dist/es';
import { Link } from 'react-router-dom';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';

import { getFormattedDateString } from '@/common/utils/date-utils';

const PAGE_SIZE = 10;

const PUSH_NOTIFICATIONS_QUERY = gql`
  query PushNotificationsQuery($search: SearchInputType) {
    pushNotificationsPagination(search: $search) {
      page
      totalPages
      results {
        id
        name
        body
        scheduledDeliveryDate
      }
    }
  }
`;

/* eslint-disable object-curly-newline */
const PushNotificationsList = ({ keyword }) => (
  <Query
    query={PUSH_NOTIFICATIONS_QUERY}
    variables={{
      search: {
        pageSize: PAGE_SIZE,
        page: 1,
        sorts: '-scheduledDeliveryDate',
        filters: `name@=${keyword}`,
      },
    }}
  >
    {({ data: { pushNotificationsPagination }, loading, variables, refetch }) => {
      if (loading) {
        return <Loader active />;
      }

      return (
        <React.Fragment>
          <Table celled sortable striped selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Body</Table.HeaderCell>
                <Table.HeaderCell>Scheduled Delivery Date</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {
                pushNotificationsPagination.results &&
                pushNotificationsPagination.results.map(({
                  id, name, body, scheduledDeliveryDate,
                }) => (
                  <Table.Row key={id}>
                    <Table.Cell>
                      <Link to={`/push-notifications/${id}`}>{name}</Link>
                    </Table.Cell>
                    <Table.Cell>{body}</Table.Cell>
                    <Table.Cell>{getFormattedDateString(scheduledDeliveryDate)}</Table.Cell>
                  </Table.Row>
                ))
              }
            </Table.Body>
          </Table>
          {pushNotificationsPagination.totalPages > 1 && (
            <Pagination
              activePage={pushNotificationsPagination.page}
              totalPages={pushNotificationsPagination.totalPages}
              onPageChange={
                (e, { activePage }) => {
                  if (activePage !== pushNotificationsPagination.page) {
                    refetch({
                      search: {
                        pageSize: PAGE_SIZE,
                        page: activePage,
                        sorts: 'name',
                        filters: `name@=${keyword}`,
                      },
                    });
                  }
                }
              }
              firstItem={
                variables.search.page === 1 ?
                  null :
                  undefined
              }
              prevItem={
                variables.search.page === 1 ?
                  null :
                  undefined
              }
              lastItem={
                variables.search.page === pushNotificationsPagination.totalPages ?
                  null :
                  undefined
              }
              nextItem={
                variables.search.page === pushNotificationsPagination.totalPages ?
                  null :
                  undefined
              }
            />
          )}
        </React.Fragment>
      );
    }}
  </Query>
);

PushNotificationsList.propTypes = {
  keyword: PropTypes.string,
};

PushNotificationsList.defaultProps = {
  keyword: '',
};

export default PushNotificationsList;
