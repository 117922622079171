const getUpdateSuccessMessage = entity => `Successfully updated ${entity}!`;
const getUpdateErrorMessage = entity => `Error updating ${entity}!`;
const getFetchErrorMessage = entity => `Error retrieving ${entity}!`;

export const LOCATION_FETCHING_REQUEST = 'LOCATION_FETCHING_REQUEST';
export const LOCATION_FETCHING_SUCCESS = 'LOCATION_FETCHING_SUCCESS';
export const LOCATION_FETCHING_ERROR = 'LOCATION_FETCHING_ERROR';

export const LOCATION_UPDATING_REQUEST = 'LOCATION_UPDATING_REQUEST';
export const LOCATION_UPDATING_SUCCESS = 'LOCATION_UPDATING_SUCCESS';
export const LOCATION_UPDATING_ERROR = 'LOCATION_UPDATING_ERROR';

export const LOCATION_IMAGE_UPLOAD_REQUEST = 'LOCATION_IMAGE_UPLOAD_REQUEST';
export const LOCATION_IMAGE_UPLOAD_SUCCESS = 'LOCATION_IMAGE_UPLOAD_SUCCESS';
export const LOCATION_IMAGE_UPLOAD_ERROR = 'LOCATION_IMAGE_UPLOAD_ERROR';

export const LOCATION_TOGGLE_REQUEST = 'LOCATION_TOGGLE_REQUEST';
export const LOCATION_TOGGLE_SUCCESS = 'LOCATION_TOGGLE_SUCCESS';
export const LOCATION_TOGGLE_ERROR = 'LOCATION_TOGGLE_ERROR';

export const LOCATION_ADDRESS_UPDATE_REQUEST = 'LOCATION_ADDRESS_UPDATE_REQUEST';
export const LOCATION_ADDRESS_UPDATE_SUCCESS = 'LOCATION_ADDRESS_UPDATE_SUCCESS';
export const LOCATION_ADDRESS_UPDATE_ERROR = 'LOCATION_ADDRESS_UPDATE_ERROR';
export const LOCATION_ADDRESS_UPDATE_SUCCESS_MESSAGE = getUpdateSuccessMessage('location address');
export const LOCATION_ADDRESS_UPDATE_ERROR_MESSAGE = getUpdateErrorMessage('location address');

export const GEOCODE_REQUEST = 'GEOCODE_REQUEST';
export const GEOCODE_SUCCESS = 'GEOCODE_SUCCESS';
export const GEOCODE_ERROR = 'GEOCODE_ERROR';

const geocodeEntity = 'geolocation details';
export const MSG_GEOCODE_SUCCESS = `${getUpdateSuccessMessage(geocodeEntity)} Save the form to commit these changes!`;
export const MSG_GEOCODE_ERROR = getFetchErrorMessage(geocodeEntity);
export const MSG_GEOCODE_UPDATE_ERROR = getUpdateErrorMessage(geocodeEntity);

export const MSG_UPDATE_SUCCESS = 'Successfully updated location!';
export const MSG_UPDATE_ERROR = 'Error updating location!';
export const MSG_FETCH_ERROR = 'Error retrieving location!';
export const MSG_IMAGE_UPLOAD_SUCCESS = 'Successfully updated location image!';
export const MSG_IMAGE_UPLOAD_INVALID = 'Unable to upload selected file. Is it in the correct format?';
export const MSG_IMAGE_UPLOAD_ERROR = 'Error updating location image!';
export const MSG_TOGGLE_ERROR = 'Error updating location status!';
export const MSG_TOGGLE_SUCCESS = 'Successfully updated location status!';
