import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Modal,
  Button,
} from 'semantic-ui-react/dist/es';

import { openModal, closeModal } from './actions';

class TestModal extends React.Component {
  onNextModalClick = () => {
    const { counter } = this.props;

    this.props.handleOpen('TestModal', { counter: counter + 1 });
  }

  render() {
    const { counter, handleClose } = this.props;

    return (
      <Modal
        closeIcon="close"
        open
        onClose={handleClose}
      >
        <Modal.Header>Modal #{counter}</Modal.Header>
        <Modal.Content image>
          <Modal.Description>
            <h4>Value from props:</h4>
            <div>
              counter = {counter}
            </div>
            <div>
              <Button onClick={this.onNextModalClick}>Add Another Modal</Button>
            </div>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions />
      </Modal>
    );
  }
}

TestModal.propTypes = {
  counter: PropTypes.number.isRequired,
  handleOpen: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  handleOpen: () => {
    dispatch(openModal());
  },
  handleClose: () => {
    dispatch(closeModal());
  },
});

export default connect(null, mapDispatchToProps)(TestModal);
