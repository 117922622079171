import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { pick } from 'lodash-es';
import Yup from 'yup';
import { Button, Divider, Form, Header, Message, Segment } from 'semantic-ui-react/dist/es';

import TimePicker from '@/common/components/TimePicker';
import { getTwentyFourHourTime, calculateTimeDifference } from '@/common/utils/date-utils';

const days = [
  { key: 1, text: 'Sunday', value: 1 },
  { key: 2, text: 'Monday', value: 2 },
  { key: 3, text: 'Tuesday', value: 3 },
  { key: 4, text: 'Wednesday', value: 4 },
  { key: 5, text: 'Thursday', value: 5 },
  { key: 6, text: 'Friday', value: 6 },
  { key: 7, text: 'Saturday', value: 7 },
];

/* eslint-disable no-unused-vars */
const OperatingHoursDetailEditForm = ({
  onSubmit,
  isSubmitting,
}) => (
  <Formik
    enableReinitialize
    initialValues={{
      startTime: '8:00',
      startMeridiem: 'AM',
      endTime: '5:00',
      endMeridiem: 'PM',
    }}
    validationSchema={Yup.object().shape({
      dayId: Yup.number().required('Day is required!'),
      startTime: Yup.string().required('Start time is required!'),
      endTime: Yup.string().required('End time is required!'),
    })}
    onSubmit={(values) => {
      const open = getTwentyFourHourTime(`${values.startTime} ${values.startMeridiem}`);
      const close = getTwentyFourHourTime(`${values.endTime} ${values.endMeridiem}`);

      const duration = calculateTimeDifference(open, close);
      onSubmit({
        ...pick(values, [
          'name',
          'dayId',
        ]),
        open,
        duration,
      });
    }}
    render={({
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      isValid,
      setFieldValue,
      setStatus,
      status,
    }) => (
      <Segment clearing>
        <Header size="medium">Add Daily Hours</Header>

        <Form onSubmit={handleSubmit} error={!!errors} loading={isSubmitting}>
          {/* eslint-disable jsx-a11y/label-has-for */}
          <Form.Dropdown
            selection
            name="dayId"
            label="Day"
            placeholder="Select Day"
            options={days}
            value={values.dayId}
            error={touched.dayId && !!errors.dayId}
            onChange={(e, { value }) => setFieldValue('dayId', value)}
            required
          />

          {
            errors &&
            errors.dayId &&
            touched.dayId && <Message negative content={errors.dayId} size="mini" />
          }

          <Form.Field required>
            <label>Start Time</label>
            <TimePicker
              id="start-time-picker"
              onFocusChange={focused => setStatus({ ...status, startTimeFocused: focused })}
              focused={status && status.startTimeFocused}
              meridiem={values.startMeridiem}
              time={values.startTime}
              onTimeChange={({ hour, minute, meridiem }) => {
                setFieldValue('startTime', `${hour}:${minute.includes('-') ? 60 - minute.split('-')[1] : minute}`);
                setFieldValue('startMeridiem', meridiem);
              }}
            />
          </Form.Field>

          <Form.Field required>
            <label>End Time</label>
            <TimePicker
              id="end-time-picker"
              onFocusChange={focused => setStatus({ ...status, endTimeFocused: focused })}
              focused={status && status.endTimeFocused}
              meridiem={values.endMeridiem}
              time={values.endTime}
              onTimeChange={({ hour, minute, meridiem }) => {
                setFieldValue('endTime', `${hour}:${minute.includes('-') ? 60 - minute.split('-')[1] : minute}`);
                setFieldValue('endMeridiem', meridiem);
              }}
            />
          </Form.Field>

          <Divider />

          <Button color="teal" disabled={isSubmitting || !isValid} floated="right">
            Add
          </Button>
        </Form>
      </Segment>
    )}
  />
);
OperatingHoursDetailEditForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default OperatingHoursDetailEditForm;
