import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Yup from 'yup';
import { pick } from 'lodash-es';
import { Button, Divider, Form, Header, Message, Segment } from 'semantic-ui-react/dist/es';
import { SingleDatePicker } from 'react-dates';
import TimePicker from '@/common/components/TimePicker';
import {
  getMomentFromDateString,
  getTimeFromDateString,
  getMeridiemFromDateString,
  getIsoFormattedString,
  DEFAULT_START_TIME,
  DEFAULT_START_MERIDIEM,
} from '@/common/utils/date-utils';

import Multiselect from '@/common/components/Multiselect';

const transformValues = (data) => {
  if (!(data && data.length)) {
    return [];
  }

  return data.map(l => ({
    key: l.id,
    value: l.id,
    text: l.name,
  }));
};

const PushNotificationEditForm = ({
  name,
  body,
  notificationType,
  scheduledDeliveryDate,
  locations,
  locationIds,
  onSubmit,
  isSubmitting,
}) => {
  const deliveryMoment = getMomentFromDateString(scheduledDeliveryDate);
  const deliveryTime = getTimeFromDateString(scheduledDeliveryDate);
  const deliveryMeridiem = getMeridiemFromDateString(scheduledDeliveryDate);
  const options = transformValues(locations);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: name || '',
        body: body || '',
        deliveryMoment,
        deliveryTime: deliveryTime || DEFAULT_START_TIME,
        deliveryMeridiem: deliveryMeridiem || DEFAULT_START_MERIDIEM,
        locationIds: locationIds || [],
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('Name is required!').max(100, 'Name must not exceed 100 characters!'),
        body: Yup.string().required('Content is required!').max(140, 'Content must not exceed 140 characters!'),
        deliveryMoment: Yup.date()
          .typeError('Delivery date is required!')
          .required('Delivery date is required!'),
        deliveryTime: Yup.string().required('Delivery time is required!'),
      })}
      onSubmit={(values) => {
        onSubmit({
          ...pick(values, ['name', 'body', 'locationIds']),
          scheduledDeliveryDate: getIsoFormattedString(
            values.deliveryMoment,
            values.deliveryTime,
            values.deliveryMeridiem,
          ),
          notificationType,
        });
      }}
      render={({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isValid,
        setFieldValue,
        setStatus,
        status,
      }) => (
        <Segment clearing>
          <Header size="medium">Push Notification</Header>

          <Form onSubmit={handleSubmit} error={!!errors} loading={isSubmitting}>
            <Form.Input
              name="name"
              label="Name"
              placeholder="Name"
              value={values.name}
              error={touched.name && !!errors.name}
              onBlur={handleBlur}
              onChange={handleChange}
              required
            />

            {
              errors && errors.name && touched.name &&
                <Message negative content={errors.name} size="mini" />
            }

            <Form.TextArea
              name="body"
              label="Content"
              placeholder="Message body"
              value={values.body}
              error={touched.body && !!errors.body}
              onBlur={handleBlur}
              onChange={handleChange}
              required
            />

            {
              errors && errors.body && touched.body &&
                <Message negative content={errors.body} size="mini" />
            }

            {/* eslint-disable jsx-a11y/label-has-for */}
            <Form.Group unstackable>
              <Form.Field required>
                <label>Delivery Date</label>

                <SingleDatePicker
                  id="delivery-date-picker"
                  date={values.deliveryMoment}
                  numberOfMonths={1}
                  onDateChange={date => setFieldValue('deliveryMoment', date)}
                  focused={status && status.deliveryMomentFocused}
                  onFocusChange={({ focused }) => {
                    setStatus({ ...status, deliveryMomentFocused: focused });
                  }}
                />
              </Form.Field>

              <Form.Field required>
                <label>Delivery Time</label>
                <TimePicker
                  id="delivery-time-picker"
                  onFocusChange={focused => setStatus({ ...status, deliveryTimeFocused: focused })}
                  focused={status && status.deliveryTimeFocused}
                  meridiem={values.deliveryMeridiem}
                  time={values.deliveryTime}
                  onTimeChange={({ hour, minute, meridiem }) => {
                    setFieldValue('deliveryTime', `${hour}:${minute.includes('-') ? 60 - minute.split('-')[1] : minute}`);
                    setFieldValue('deliveryMeridiem', meridiem);
                  }}
                />
              </Form.Field>
            </Form.Group>

            {
              errors && errors.deliveryMoment &&
                <Message negative content={errors.deliveryMoment} size="mini" />
            }

            {
              options && options.length > 0 &&
              <Form.Field>
                <label>Locations</label>
                <Multiselect
                  options={options}
                  value={values.locationIds}
                  handleChange={(e, { value }) => setFieldValue('locationIds', value)}
                />
              </Form.Field>
            }

            <Divider />

            <Button color="teal" disabled={isSubmitting || !isValid} floated="right">
              Save
            </Button>
          </Form>
        </Segment>
      )}
    />
  );
};

PushNotificationEditForm.propTypes = {
  name: PropTypes.string,
  body: PropTypes.string,
  notificationType: PropTypes.string,
  scheduledDeliveryDate: PropTypes.string,
  locations: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })),
  locationIds: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
    text: PropTypes.text,
  })),
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

PushNotificationEditForm.defaultProps = {
  name: '',
  body: '',
  notificationType: '',
  scheduledDeliveryDate: null,
  locations: [],
  locationIds: [],
};

export default PushNotificationEditForm;
