import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react/dist/es';

const StatusIndicator = ({ enabled }) => {
  const iconColor = enabled ? 'green' : 'red';
  const iconName = enabled ? 'checkmark' : 'close';

  return (
    <Icon color={iconColor} name={iconName} />
  );
};

StatusIndicator.propTypes = {
  enabled: PropTypes.bool.isRequired,
};

export default StatusIndicator;
