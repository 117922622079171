import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router-dom';

import PushNotificationsPage from './pages/PushNotificationsPage';
import PushNotificationEditPage from './pages/PushNotificationEditPage';

const PushNotifications = ({ match }) => (
  <Switch>
    <Route path={`${match.url}`} exact component={PushNotificationsPage} />
    <Route path={`${match.url}/create`} exact component={PushNotificationEditPage} />
    <Route path={`${match.url}/:id`} exact component={PushNotificationEditPage} />
  </Switch>
);

PushNotifications.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(PushNotifications);
