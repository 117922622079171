import localConfig from './config.local.json';
import devAzConfig from './config.dev.az.json';
import uatAzConfig from './config.uat.az.json';
import prodAzConfig from './config.prod.az.json';
import devConfig from './config.dev.json';
import uatConfig from './config.uat.json';
import prodConfig from './config.prod.json';

const isEnv = (prefix) => {
  const { hostname } = window.location;
  return hostname.indexOf(prefix) > -1;
};

const getConfig = () => {
  const isLocalhost = isEnv('localhost');
  const isDevAz = isEnv('dev') && isEnv('-az');
  const isUatAz = isEnv('uat') && isEnv('-az');
  const isProdAz = isEnv('enp-az.aspirant.com') || isEnv('prod');
  const isDev = isEnv('dev');
  const isUat = isEnv('uat');
  const isProd = isEnv('enp.aspirant.com');

  if (isLocalhost) {
    return localConfig;
  } if (isDevAz) {
    return devAzConfig;
  } if (isUatAz) {
    return uatAzConfig;
  } if (isProdAz) {
    return prodAzConfig;
  } if (isDev) {
    return devConfig;
  } if (isUat) {
    return uatConfig;
  } if (isProd) {
    return prodConfig;
  }
  console.log(`No configuration matching ${window.location.hostname} was found`);
  return {};
};

export default getConfig;
