import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';

import NavBreadcrumb, { formatBreadcrumb } from '@/common/components/NavBreadcrumb';
import { selectedLocationName } from '@/features/locations/selectors';
import OperatingHoursBreadcrumb from '@/features/hours-of-operation/OperatingHoursBreadcrumb';

const LocationBreadcrumb = ({ name }) => <span>{formatBreadcrumb(name)}</span>;

LocationBreadcrumb.propTypes = {
  name: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  name: selectedLocationName(state),
});

const ConnectedBreadcrumb = connect(mapStateToProps)(LocationBreadcrumb);

const routes = [
  { path: '/', breadcrumb: null },
  { path: '/locations', breadcrumb: 'Locations' },
  { path: '/locations/create', breadcrumb: 'New Location' },
  { path: '/locations/:id', breadcrumb: ConnectedBreadcrumb },
  { path: '/locations/:id/hours', breadcrumb: 'Hours of Operation' },
  { path: '/locations/:id/hours/create', breadcrumb: 'Create a Schedule' },
  { path: '/locations/:id/hours/:hoursId', breadcrumb: OperatingHoursBreadcrumb },
];

export default withBreadcrumbs(routes)(NavBreadcrumb);
