import {
  LOCATION_FETCHING_REQUEST,
  LOCATION_FETCHING_SUCCESS,
  LOCATION_FETCHING_ERROR,
  LOCATION_UPDATING_REQUEST,
  LOCATION_UPDATING_SUCCESS,
  LOCATION_UPDATING_ERROR,
  LOCATION_IMAGE_UPLOAD_REQUEST,
  LOCATION_IMAGE_UPLOAD_ERROR,
  LOCATION_IMAGE_UPLOAD_SUCCESS,
  LOCATION_TOGGLE_REQUEST,
  LOCATION_TOGGLE_ERROR,
  LOCATION_TOGGLE_SUCCESS,
  GEOCODE_REQUEST,
  GEOCODE_SUCCESS,
  GEOCODE_ERROR,
  LOCATION_ADDRESS_UPDATE_REQUEST,
  LOCATION_ADDRESS_UPDATE_SUCCESS,
  LOCATION_ADDRESS_UPDATE_ERROR,
} from './constants';

const initialState = {
  requesting: false,
  successful: false,
  selectedLocation: {},
  errors: [],
};

export default (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case LOCATION_FETCHING_REQUEST:
      return {
        requesting: true,
        successful: false,
        selectedLocation: {},
        errors: [],
      };
    case LOCATION_FETCHING_SUCCESS:
      return {
        requesting: false,
        successful: true,
        selectedLocation: action.payload.location,
        errors: [],
      };
    case LOCATION_FETCHING_ERROR:
      return {
        requesting: false,
        successful: false,
        selectedLocation: {},
        errors: state.errors.concat([{
          body: action.error.toString(),
          time: new Date(),
        }]),
      };
    case LOCATION_UPDATING_REQUEST:
      return {
        ...state,
        requesting: true,
        successful: false,
        errors: [],
      };
    case LOCATION_UPDATING_SUCCESS:
      return {
        ...state,
        requesting: false,
        successful: true,
        errors: [],
      };
    case LOCATION_UPDATING_ERROR:
      return {
        ...state,
        requesting: false,
        successful: false,
        errors: state.errors.concat([{
          body: action.error.toString(),
          time: new Date(),
        }]),
      };
    case LOCATION_IMAGE_UPLOAD_REQUEST:
      return {
        ...state,
        requesting: true,
        successful: false,
        errors: [],
      };
    case LOCATION_IMAGE_UPLOAD_SUCCESS:
      return {
        ...state,
        selectedLocation: {
          ...state.selectedLocation,
          imageName: action.payload.imageName,
        },
        requesting: false,
        successful: true,
        errors: [],
      };
    case LOCATION_IMAGE_UPLOAD_ERROR:
      return {
        ...state,
        requesting: false,
        successful: false,
        errors: state.errors.concat([{
          body: action.error.toString(),
          time: new Date(),
        }]),
      };
    case LOCATION_TOGGLE_REQUEST:
      return {
        ...state,
        requesting: true,
        successful: false,
        errors: [],
      };
    case LOCATION_TOGGLE_SUCCESS:
      return {
        ...state,
        selectedLocation: {
          ...state.selectedLocation,
          enabled: !state.selectedLocation.enabled,
        },
        requesting: false,
        successful: true,
        errors: [],
      };
    case LOCATION_TOGGLE_ERROR:
      return {
        ...state,
        requesting: false,
        successful: false,
        errors: state.errors.concat([{
          body: action.error.toString(),
          time: new Date(),
        }]),
      };
    case GEOCODE_REQUEST:
      return {
        ...state,
        requesting: true,
        successful: false,
        errors: [],
      };
    case GEOCODE_SUCCESS:
      return {
        ...state,
        selectedLocation: {
          ...state.selectedLocation,
          address: action.payload.address,
          latitude: action.payload.latitude,
          longitude: action.payload.longitude,
        },
        requesting: false,
        successful: true,
        errors: [],
      };
    case GEOCODE_ERROR:
      return {
        ...state,
        requesting: false,
        successful: false,
        errors: state.errors.concat([{
          body: action.error.toString(),
          time: new Date(),
        }]),
      };
    case LOCATION_ADDRESS_UPDATE_REQUEST:
      return {
        ...state,
        requesting: true,
        successful: false,
        errors: [],
      };
    case LOCATION_ADDRESS_UPDATE_SUCCESS:
      return {
        ...state,
        requesting: false,
        successful: true,
        errors: [],
      };
    case LOCATION_ADDRESS_UPDATE_ERROR:
      return {
        ...state,
        requesting: false,
        successful: false,
        errors: state.errors.concat([{
          body: action.error.toString(),
          time: new Date(),
        }]),
      };
    default:
      return state;
  }
};
