import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Yup from 'yup';
import { Button, Divider, Form, Header, Message, Segment } from 'semantic-ui-react/dist/es';

const LocationGroupEditForm = ({
  name,
  description,
  onSubmit,
  isSubmitting,
}) => (
  <Formik
    enableReinitialize
    initialValues={{
      name: name || '',
      description: description || '',
    }}
    validationSchema={Yup.object().shape({
      name: Yup.string()
        .required('Name is required!')
        .max(100, 'Name must not exceed 100 characters!'),
      description: Yup.string().max(140, 'Description must not exceed 500 characters!'),
    })}
    onSubmit={values => onSubmit(values)}
    render={({
      values, errors, touched, handleChange, handleBlur, handleSubmit, isValid,
    }) => (
      <Segment clearing>
        <Header size="medium">Location Group</Header>

        <Form onSubmit={handleSubmit} error={!!errors} loading={isSubmitting}>
          <Form.Input
            name="name"
            label="Name"
            placeholder="Name"
            value={values.name}
            error={touched.name && !!errors.name}
            onBlur={handleBlur}
            onChange={handleChange}
            required
          />

          {errors &&
            errors.name &&
            touched.name && <Message negative content={errors.name} size="mini" />}

          <Form.TextArea
            name="description"
            label="Description"
            placeholder="Description"
            value={values.description}
            error={touched.description && !!errors.description}
            onBlur={handleBlur}
            onChange={handleChange}
          />

          {errors &&
            errors.description &&
            touched.description && <Message negative content={errors.description} size="mini" />}

          <Divider />

          <Button color="teal" disabled={isSubmitting || !isValid} floated="right">
            Save
          </Button>
        </Form>
      </Segment>
    )}
  />
);

LocationGroupEditForm.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

LocationGroupEditForm.defaultProps = {
  name: '',
  description: '',
};

export default LocationGroupEditForm;
