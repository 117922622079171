import React from 'react';
import PropTypes from 'prop-types';
import { Table, Loader } from 'semantic-ui-react/dist/es';
import { Link, withRouter } from 'react-router-dom';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { isEmpty } from 'lodash-es';

import { getFormattedDateString } from '@/common/utils/date-utils';
import NoItems from '@/common/components/NoItems';

const PAGE_SIZE = 10;

const OPERATING_HOURS_QUERY = gql`
  query OperatingHoursQuery($id: Int!, $hoursSearch: SearchInputType) {
    location(id: $id) {
      id
      hours(hoursSearch: $hoursSearch) {
        id
        startDate
        endDate
        name
      }
    }
  }
`;

const OperationHoursList = ({ match, keyword }) => (
  <Query
    query={OPERATING_HOURS_QUERY}
    variables={{
      id: match.params.id,
      search: {
        pageSize: PAGE_SIZE,
        page: 1,
        sorts: 'name',
        filters: `name@=${keyword}`,
      },
    }}
  >
    {({ data: { location }, loading }) => {
      if (loading) {
        return <Loader active />;
      }

      const { hours } = location;

      if (isEmpty(hours)) {
        return <NoItems />;
      }

      return (
        <Table celled sortable striped selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Start Date</Table.HeaderCell>
              <Table.HeaderCell>End Date</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              hours && hours.map(({
                id, name, startDate, endDate,
              }) => (
                <Table.Row key={id}>
                  <Table.Cell>
                    <Link to={`${match.url}/${id}`}>{name}</Link>
                  </Table.Cell>
                  <Table.Cell>{getFormattedDateString(startDate)}</Table.Cell>
                  <Table.Cell>{getFormattedDateString(endDate)}</Table.Cell>
                </Table.Row>
              ))
            }
          </Table.Body>
        </Table>
      );
    }}
  </Query>
);

OperationHoursList.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  keyword: PropTypes.string,
};

OperationHoursList.defaultProps = {
  keyword: '',
};

export default withRouter(OperationHoursList);
