import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';

import { history } from '@/app/store';
import api from '@/common/api';
import PushNotificationEditForm from '@/features/push-notifications/PushNotificationEditForm';

const UPDATE_SUCCESS = 'Successfully updated push notification!';
const UPDATE_ERROR = 'Error updating push notification!';

class PushNotificationEditPage extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string,
      }).isRequired,
    }).isRequired,
  };

  state = {
    loading: false,
    notification: {},
    locations: [],
  };

  async componentDidMount() {
    const { id } = this.props.match.params;

    try {
      let notification = {};
      if (id) {
        ({ data: notification } = await api.get(`/api/pushnotification/${id}`));
      }

      const { data: locations } = await api.get('api/location');

      this.setState({
        loading: false,
        notification,
        locations,
      });
    } catch (e) {
      toast.error(UPDATE_ERROR);
    }
  }

  handleSubmit = (notification) => {
    const { id } = this.props.match.params;

    this.setState({ loading: true }, async () => {
      try {
        const promise = id ?
          api.put(`/api/pushNotification/${id}`, notification)
          : api.post('/api/pushNotification', notification);

        const response = await promise;
        toast.success(UPDATE_SUCCESS);
        if (!id) {
          history.push(`/push-notifications/${response.data.id}`);
        }
      } catch (e) {
        toast.error(UPDATE_ERROR);
      }

      this.setState({ loading: false });
    });
  };

  render() {
    return (
      <PushNotificationEditForm
        {...this.state.notification}
        locations={this.state.locations}
        onSubmit={this.handleSubmit}
        isSubmitting={this.state.loading}
      />
    );
  }
}

export default withRouter(PushNotificationEditPage);
