import React from 'react';
import { Redirect } from 'react-router-dom';
import userManager from '@/common/utils/user-manager';

export default class Logout extends React.Component {
  componentDidMount() {
    userManager.removeUser();
  }

  render() {
    return (
      <Redirect to="/" push />
    );
  }
}
