import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router-dom';

import MessagesPage from './pages/MessagesPage';
import MessageEditPage from './pages/MessageEditPage';

const Messages = ({ match }) => (
  <Switch>
    <Route path={`${match.url}`} exact component={MessagesPage} />
    <Route path={`${match.url}/create`} exact component={MessageEditPage} />
    <Route path={`${match.url}/:id`} exact component={MessageEditPage} />
  </Switch>
);

Messages.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(Messages);
