import { style } from 'react-toastify';

// https://github.com/fkhadra/react-toastify
// TODO: Configure default, info, and warning toasts to match Semantic UI
const configureToasts = () => {
  style({
    width: '320px',
    colorDefault: '#fff',
    colorInfo: '#3498db',
    colorSuccess: '#16ab39',
    colorWarning: '#f1c40f',
    colorError: '#db2828',
    colorProgressDefault: 'linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55)',
    mobile: 'only screen and (max-width : 480px)',
    fontFamily: 'sans-serif',
  });
};

export default configureToasts;
