import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import AbsolutePosition from '@/common/components/AbsolutePosition';
import { hideContextMenu } from './actions';

const actions = { hideContextMenu };

class ContextMenu extends React.Component {
  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  handleClickOutside = (e) => {
    if (!this.node || !this.node.contains(e.target)) {
      this.props.hideContextMenu();
    }
  }

  render() {
    const { location } = this.props;

    return (
      <AbsolutePosition
        left={location.x + 2}
        top={location.y}
        className="contextMenu"
        nodeRef={(node) => { this.node = node; }}
      >
        {this.props.children}
      </AbsolutePosition>
    );
  }
}

ContextMenu.propTypes = {
  children: PropTypes.node.isRequired,
  hideContextMenu: PropTypes.func.isRequired,
  location: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  }).isRequired,
};

export default connect(null, actions)(ContextMenu);
