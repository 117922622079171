import React from 'react';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { history } from '@/app/store';

import api from '@/common/api';
import LocationEditForm from '@/features/locations/LocationEditForm/LocationEditForm';

const CREATE_SUCCESS = 'Successfully created location!';
const CREATE_ERROR = 'Error creating location!';

class LocationCreateFormContainer extends React.Component {
  state = {
    loading: false,
  };

  handleSubmit = (location) => {
    this.setState({ loading: true }, async () => {
      try {
        const response = await api.post('/api/location', location);
        toast.success(CREATE_SUCCESS);
        history.push(`/locations/${response.data.id}`);
      } catch (e) {
        toast.error(CREATE_ERROR);
      }

      this.setState({ loading: false });
    });
  };

  render() {
    const location = {};
    return (
      <LocationEditForm
        {...location}
        onSubmit={this.handleSubmit}
        isSubmitting={this.state.loading}
      />
    );
  }
}

export default withRouter(LocationCreateFormContainer);
