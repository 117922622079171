import {
  LOCATION_FETCHING_REQUEST,
  LOCATION_FETCHING_SUCCESS,
  LOCATION_UPDATING_REQUEST,
  LOCATION_IMAGE_UPLOAD_REQUEST,
  LOCATION_IMAGE_UPLOAD_SUCCESS,
  LOCATION_TOGGLE_REQUEST,
  GEOCODE_REQUEST,
  GEOCODE_SUCCESS,
  LOCATION_ADDRESS_UPDATE_REQUEST,
} from './constants';

export const fetchLocationRequest = id => ({
  type: LOCATION_FETCHING_REQUEST,
  payload: {
    id,
  },
});

export const fetchLocationSuccess = location => ({
  type: LOCATION_FETCHING_SUCCESS,
  payload: {
    location,
  },
});

export const updateLocationRequest = (id, location) => ({
  type: LOCATION_UPDATING_REQUEST,
  payload: {
    id,
    location,
  },
});

export const updateLocationImageRequest = (id, files) => ({
  type: LOCATION_IMAGE_UPLOAD_REQUEST,
  payload: {
    id,
    files,
  },
});

export const updateLocationImageSuccess = imageName => ({
  type: LOCATION_IMAGE_UPLOAD_SUCCESS,
  payload: {
    imageName,
  },
});

export const toggleLocationRequest = (id, enabled) => ({
  type: LOCATION_TOGGLE_REQUEST,
  payload: {
    id,
    enabled,
  },
});

export const geocodeRequest = address => ({
  type: GEOCODE_REQUEST,
  payload: {
    address,
  },
});

export const geocodeSuccess = (address, latitude, longitude) => ({
  type: GEOCODE_SUCCESS,
  payload: {
    address,
    latitude,
    longitude,
  },
});

export const updateAddressRequest = (
  id,
  address1,
  address2,
  city,
  state,
  zipCode,
  geoLocationLookupAddress,
  latitude,
  longitude,
) => ({
  type: LOCATION_ADDRESS_UPDATE_REQUEST,
  payload: {
    id,
    address1,
    address2,
    city,
    state,
    zipCode,
    geoLocationLookupAddress,
    latitude,
    longitude,
  },
});
