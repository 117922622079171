import React from 'react';
import { Route, withRouter } from 'react-router-dom';

import AppInstallsContainer from '@/features/reports/AppInstalls/AppInstallsContainer';

const AppInstallsPage = () => (
  <Route component={AppInstallsContainer} />
);

export default withRouter(AppInstallsPage);
