import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Yup from 'yup';
import { pick } from 'lodash-es';
import { Button, Divider, Form, Header, Message, Segment } from 'semantic-ui-react/dist/es';
import { SingleDatePicker } from 'react-dates';
import TimePicker from '@/common/components/TimePicker';
import {
  getMomentFromDateString,
  getTimeFromDateString,
  getMeridiemFromDateString,
  getIsoFormattedString,
  DEFAULT_START_TIME,
  DEFAULT_START_MERIDIEM,
  DEFAULT_END_TIME,
  DEFAULT_END_MERIDIEM,
} from '@/common/utils/date-utils';

const SurveyEditForm = ({
  name,
  startDate,
  endDate,
  description,
  onSubmit,
  isSubmitting,
}) => {
  const startMoment = getMomentFromDateString(startDate);
  const startTime = getTimeFromDateString(startDate);
  const startMeridiem = getMeridiemFromDateString(startDate);

  const endMoment = getMomentFromDateString(endDate);
  const endTime = getTimeFromDateString(endDate);
  const endMeridiem = getMeridiemFromDateString(endDate);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: name || '',
        description: description || '',
        startMoment,
        startTime: startTime || DEFAULT_START_TIME,
        startMeridiem: startMeridiem || DEFAULT_START_MERIDIEM,
        endMoment,
        endTime: endTime || DEFAULT_END_TIME,
        endMeridiem: endMeridiem || DEFAULT_END_MERIDIEM,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .required('Name is required!')
          .max(250, 'Name must not exceed 250 characters!'),
        description: Yup.string().max(500, 'Description must not exceed 500 characters!'),
        startMoment: Yup.date().required('Start date is required!'),
        startTime: Yup.string().required('Start time is required!'),
        endMoment: Yup.date().required('End date is required!'),
        endTime: Yup.string().required('End time is required!'),
      })}
      onSubmit={(values) => {
        onSubmit({
          ...pick(values, ['name', 'description']),
          startDate: getIsoFormattedString(
            values.startMoment,
            values.startTime,
            values.startMeridiem,
          ),
          endDate: getIsoFormattedString(values.endMoment, values.endTime, values.endMeridiem),
        });
      }}
      render={({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isValid,
        setFieldValue,
        setStatus,
        status,
      }) => (
        <Segment clearing>
          <Header size="medium">Survey</Header>

          <Form onSubmit={handleSubmit} error={!!errors} loading={isSubmitting}>
            <Form.Input
              name="name"
              label="Name"
              placeholder="Name"
              value={values.name}
              error={touched.name && !!errors.name}
              onBlur={handleBlur}
              onChange={handleChange}
              required
            />

            {errors &&
              errors.name &&
              touched.name && <Message negative content={errors.name} size="mini" />}

            <Form.TextArea
              name="description"
              label="Description"
              placeholder="Description"
              value={values.description}
              error={touched.description && !!errors.description}
              onBlur={handleBlur}
              onChange={handleChange}
              required
            />

            {
              errors && errors.description && touched.description &&
                <Message negative content={errors.description} size="mini" />
            }

            {/* eslint-disable jsx-a11y/label-has-for */}
            <Form.Group unstackable>
              <Form.Field required>
                <label>Start Date</label>

                <SingleDatePicker
                  id="start-date-picker"
                  date={values.startMoment}
                  numberOfMonths={1}
                  onDateChange={date => setFieldValue('startMoment', date)}
                  focused={status && status.startDateFocused}
                  onFocusChange={({ focused }) =>
                    setStatus({ ...status, startDateFocused: focused })
                  }
                />
              </Form.Field>

              <Form.Field required>
                <label>Start Time</label>
                <TimePicker
                  id="start-time-picker"
                  onFocusChange={focused => setStatus({ ...status, startTimeFocused: focused })}
                  focused={status && status.startTimeFocused}
                  meridiem={values.startMeridiem}
                  time={values.startTime}
                  onTimeChange={({ hour, minute, meridiem }) => {
                    setFieldValue('startTime', `${hour}:${minute.includes('-') ? 60 - minute.split('-')[1] : minute}`);
                    setFieldValue('startMeridiem', meridiem);
                  }}
                />
              </Form.Field>
            </Form.Group>

            {errors &&
              errors.startMoment &&
              touched.startMoment && (
                <Message negative content={errors.startMoment} size="mini" />
              )}

            {/* eslint-disable jsx-a11y/label-has-for */}
            <Form.Group unstackable>
              <Form.Field required>
                <label>End Date</label>

                <SingleDatePicker
                  id="end-date-picker"
                  date={values.endMoment}
                  numberOfMonths={1}
                  onDateChange={date => setFieldValue('endMoment', date)}
                  focused={status && status.endDateFocused}
                  onFocusChange={({ focused }) => setStatus({ ...status, endDateFocused: focused })}
                />
              </Form.Field>

              <Form.Field required>
                <label>End Time</label>
                <TimePicker
                  id="end-time-picker"
                  onFocusChange={focused => setStatus({ ...status, endTimeFocused: focused })}
                  focused={status && status.endTimeFocused}
                  meridiem={values.endMeridiem}
                  time={values.endTime}
                  onTimeChange={({ hour, minute, meridiem }) => {
                    setFieldValue('endTime', `${hour}:${minute.includes('-') ? 60 - minute.split('-')[1] : minute}`);
                    setFieldValue('endMeridiem', meridiem);
                  }}
                />
              </Form.Field>
            </Form.Group>

            {errors &&
              errors.endMoment &&
              touched.endMoment && (
                <Message negative content={errors.endMoment} size="mini" />
              )}

            <Divider />

            <Button color="teal" disabled={isSubmitting || !isValid} floated="right">
              Save
            </Button>
          </Form>
        </Segment>
      )}
    />
  );
};

SurveyEditForm.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

SurveyEditForm.defaultProps = {
  name: '',
  description: '',
  startDate: null,
  endDate: null,
};

export default SurveyEditForm;
