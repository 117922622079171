import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Portal } from 'react-portal';

import ContextMenu from './ContextMenu';
import TestContextMenu from './TestContextMenu';
import { selectContextMenu } from './selectors';

const menuTypes = {
  TestContextMenu,
};

class ContextMenuManager extends React.Component {
  render() {
    const { contextMenu } = this.props;
    const {
      show,
      location,
      type,
      menuArgs = {},
    } = contextMenu;

    let menu = null;

    if (show) {
      const MenuComponent = menuTypes[type];

      if (MenuComponent) {
        menu = (
          <Portal isOpened>
            <ContextMenu location={location}>
              <MenuComponent {...menuArgs} />
            </ContextMenu>
          </Portal>
        );
      }
    }

    return menu;
  }
}

export function contextMenuManagerMapState(state) {
  return {
    contextMenu: selectContextMenu(state),
  };
}

ContextMenuManager.propTypes = {
  contextMenu: PropTypes.shape({
    show: PropTypes.bool.isRequired,
    location: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number,
    }).isRequired,
    type: PropTypes.string,
  }).isRequired,
};

export default connect(contextMenuManagerMapState)(ContextMenuManager);
