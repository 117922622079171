import React from 'react';
import PropTypes from 'prop-types';
import TimePicker from 'react-times';
import './TimePicker.css';

const TimePickerWrapper = ({
  focused,
  meridiem,
  time,
  timezone,
  onFocusChange,
  onTimeChange,
  ...props
}) => (
  <TimePicker
    {...props}
    focused={focused}
    timeMode="12"
    meridiem={meridiem}
    timezone={timezone}
    showTimezone
    onFocusChange={onFocusChange}
    onTimeChange={onTimeChange}
    time={time}
    theme="material"
    minuteStep={1}
  />
);

TimePickerWrapper.propTypes = {
  defaultTime: PropTypes.string,
  meridiem: PropTypes.string,
  time: PropTypes.string,
  focused: PropTypes.bool,
  timezone: PropTypes.string,
  onFocusChange: PropTypes.func.isRequired,
  onTimeChange: PropTypes.func.isRequired,
};

TimePickerWrapper.defaultProps = {
  defaultTime: null,
  focused: false,
  meridiem: null,
  timezone: null,
  time: '',
};

export default TimePickerWrapper;
