import React from 'react';
import { toast } from 'react-toastify';

import api from '@/common/api';
import MenuUpload from '@/features/franchise/MenuUpload';

const UPDATE_SUCCESS = 'Successfully updated menu!';
const UPDATE_ERROR = 'Error updating menu!';

class MenuUploadContainer extends React.Component {
  handleUpload = async (files) => {
    if (!(files && files.length)) {
      toast.error('Unable to upload selected file. Is it in the correct format?');
      return;
    }

    const file = files[0];
    const data = new FormData();
    data.append('file', file, file.name);

    try {
      await api.put('/api/menu', data, {
        headers: { 'content-type': 'multipart/form-data' },
      });

      toast.success(UPDATE_SUCCESS);
    } catch (e) {
      toast.error(UPDATE_ERROR);
    }
  };

  render() {
    return (
      <MenuUpload onUpload={this.handleUpload} />
    );
  }
}

export default MenuUploadContainer;
