import React from 'react';
import { Button } from 'semantic-ui-react/dist/es';
import {
  Chart,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartTitle,
} from '@progress/kendo-react-charts';
import api from '@/common/api';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';

class AppInstallsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fromDate: moment().startOf('month'),
      toDate: moment().endOf('month'),
      data: null,
      focusedInput: null,
    };
  }

  componentWillMount() {
    this.getData();
  }

  getData = () => {
    api.get(`api/reports/appinstalls?fromDate=${this.state.fromDate.format('YYYY-MM-DD')}&toDate=${this.state.toDate.format('YYYY-MM-DD')}`)
      .then((response) => {
        this.dataRecieved(response.data);
      });
  }

  dataRecieved = (data) => {
    this.setState(prevState => ({
      ...prevState,
      data,
    }));
  };

  render() {
    return (
      <React.Fragment>
        <DateRangePicker
          startDateId="fromDate"
          endDateId="toDate"
          startDate={this.state.fromDate}
          endDate={this.state.toDate}
          onDatesChange={
            ({ startDate, endDate }) => {
              this.setState({ fromDate: startDate, toDate: endDate });
            }
          }
          focusedInput={this.state.focusedInput}
          onFocusChange={(focusedInput) => { this.setState({ focusedInput }); }}
          isOutsideRange={() => false}
        />
        <Button color="teal" floated="right" onClick={this.getData}>
          Reload Data
        </Button>
        <Chart>
          <ChartTitle text="App Installs" />
          <ChartLegend />
          <ChartSeries>
            <ChartSeriesItem
              data={this.state.data}
              type="column"
              field="count"
              categoryField="name"
            />
          </ChartSeries>
        </Chart>
      </React.Fragment>
    );
  }
}

export { AppInstallsContainer as default };
