import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Icon, Input, Menu } from 'semantic-ui-react/dist/es';
import { debounce } from 'lodash-es';

import PushNotificationsList from '@/features/push-notifications/PushNotificationsList';

const PushNotificationsMenu = ({ onSearchChange }) => (
  <Menu stackable borderless secondary>
    <Menu.Item>
      <Button as={Link} to="/push-notifications/create" icon primary size="mini" labelPosition="left">
        <Icon name="add" />
        Create Push Notification
      </Button>
    </Menu.Item>
    <Menu.Item position="right">
      <Input
        className="icon"
        icon="search"
        placeholder="Search..."
        onChange={onSearchChange}
      />
    </Menu.Item>
  </Menu>
);

PushNotificationsMenu.propTypes = {
  onSearchChange: PropTypes.func.isRequired,
};

class PushNotificationsPage extends React.Component {
  state = {
    keyword: '',
  }

  handleSearchChange = debounce((e, data) => {
    this.setState({
      keyword: data.value,
    });
  }, 500);

  render() {
    return (
      <React.Fragment>
        <PushNotificationsMenu onSearchChange={this.handleSearchChange} />
        <PushNotificationsList keyword={this.state.keyword} />
      </React.Fragment>
    );
  }
}

export default PushNotificationsPage;
