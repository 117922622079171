import React from 'react';
import { Button } from 'semantic-ui-react/dist/es';
import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartTitle,
  ChartTooltip,
} from '@progress/kendo-react-charts';
import api from '@/common/api';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';

class ConsumerActionsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fromDate: moment().startOf('month'),
      toDate: moment().endOf('month'),
      // actionId: null,
      data: null,
    };
  }

  componentWillMount() {
    this.getData();
  }

  getData = () => {
    api.get(`api/reports/consumerActions?fromDate=${this.state.fromDate.format('YYYY-MM-DD')}&toDate=${this.state.toDate.format('YYYY-MM-DD')}`)
      .then((response) => {
        this.dataRecieved(response.data);
      });
  }

  dataRecieved = (data) => {
    this.setState(prevState => ({
      ...prevState,
      data,
    }));
  };

  render() {
    return (
      <React.Fragment>
        <DateRangePicker
          startDateId="fromDate"
          endDateId="toDate"
          startDate={this.state.fromDate}
          endDate={this.state.toDate}
          onDatesChange={
            ({ startDate, endDate }) => {
              this.setState({ fromDate: startDate, toDate: endDate });
            }
          }
          focusedInput={this.state.focusedInput}
          onFocusChange={(focusedInput) => { this.setState({ focusedInput }); }}
          isOutsideRange={() => false}
        />
        <Button color="teal" floated="right" onClick={this.getData}>
          Reload Data
        </Button>
        <Chart pannable>
          <ChartLegend />
          <ChartTitle text="Consumer Actions" />
          <ChartTooltip format="{0}" />
          <ChartCategoryAxis>
            <ChartCategoryAxisItem labels={{ rotation: 'auto' }} />
          </ChartCategoryAxis>
          <ChartSeries>
            <ChartSeriesItem
              data={this.state.data}
              name="Android"
              type="column"
              field="android"
              categoryField="action"
            />
            <ChartSeriesItem
              data={this.state.data}
              name="iOS"
              type="column"
              field="iOS"
              categoryField="action"
            />
            <ChartSeriesItem
              data={this.state.data}
              name="Total"
              type="column"
              field="total"
              categoryField="action"
            />
          </ChartSeries>
        </Chart>
      </React.Fragment>
    );
  }
}

export { ConsumerActionsContainer as default };
