import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// eslint-disable-next-line max-len
// import LocationAddressPickerModal from '@/features/locations/LocationAddress/LocationAddressPickerModal';
import TestModal from './TestModal';

const modalComponentLookupTable = {
  // LocationAddressPickerModal,
  TestModal,
};

const ModalManager = ({ currentModals }) => {
  const renderedModals = currentModals.map((modalDescription, index) => {
    const { modalType, modalProps = {} } = modalDescription;
    const ModalComponent = modalComponentLookupTable[modalType];

    // eslint-disable-next-line react/no-array-index-key
    return <ModalComponent {...modalProps} key={modalType + index} />;
  });

  return (<span>{renderedModals}</span>);
};

ModalManager.propTypes = {
  currentModals: PropTypes.arrayOf(PropTypes.func).isRequired,
};

const mapStateToProps = state => ({
  currentModals: state.modals,
});

export default connect(mapStateToProps)(ModalManager);
