import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Yup from 'yup';
import { Button, Checkbox, Divider, Form, Header, Message, Segment } from 'semantic-ui-react/dist/es';

// TODO: Implement unique name check: https://github.com/jaredpalmer/formik/issues/24
const LocationEditForm = ({
  name,
  displayName,
  description,
  onlineOrderUrl,
  phone,
  hasPickupWindow,
  open24Hours,
  onSubmit,
  isSubmitting,
}) => (
  <Segment clearing>
    <Formik
      enableReinitialize
      initialValues={{
        name: name || '',
        displayName: displayName || '',
        description: description || '',
        onlineOrderUrl: onlineOrderUrl || '',
        phone: phone || '',
        hasPickupWindow,
        open24Hours,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('Location name is required!'),
        displayName: Yup.string().required('Display name is required!'),
        description: Yup.string().required('Description is required!'),
        onlineOrderUrl: Yup.string()
          .required('Online order URL is required!')
          .url('Online order URL must be valid!'),
        phone: Yup.string()
          .required('Phone # is required!'),
      })}
      onSubmit={values => onSubmit(values)}
      render={({
        values, errors, touched, handleChange, handleBlur, handleSubmit, isValid, setFieldValue,
      }) => (
        <React.Fragment>
          <Header size="medium">General</Header>

          <Form onSubmit={handleSubmit} error={!!errors} loading={isSubmitting}>
            <Form.Group widths="equal">
              <Form.Input
                name="name"
                label="Location name"
                placeholder="Location name"
                value={values.name}
                error={touched.name && !!errors.name}
                onBlur={handleBlur}
                onChange={handleChange}
                required
              />

              <Form.Input
                name="displayName"
                label="Display name"
                placeholder="Display name"
                value={values.displayName}
                error={touched.displayName && !!errors.displayName}
                onBlur={handleBlur}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.TextArea
              name="description"
              label="Description"
              placeholder="Tell us more about the restaurant..."
              value={values.description}
              error={touched.description && !!errors.description}
              onBlur={handleBlur}
              onChange={handleChange}
              required
            />

            {
              errors && errors.description && touched.description &&
                <Message negative content={errors.description} size="mini" />
            }

            <Form.Group>
              <Form.Input
                name="onlineOrderUrl"
                label="Online Order URL"
                placeholder="Online Order URL"
                value={values.onlineOrderUrl}
                error={touched.onlineOrderUrl && !!errors.onlineOrderUrl}
                onBlur={handleBlur}
                onChange={handleChange}
                required
                width={12}
              />
              <Form.Input
                name="phone"
                label="Phone #"
                placeholder="123-456-7890"
                value={values.phone}
                error={touched.phone && !!errors.phone}
                onBlur={handleBlur}
                onChange={handleChange}
                required
                width={4}
              />
            </Form.Group>

            {
              errors && errors.onlineOrderUrl && touched.onlineOrderUrl &&
                <Message negative content={errors.onlineOrderUrl} size="mini" />
            }

            {
              errors && errors.phone && touched.phone &&
                <Message negative content={errors.phone} size="mini" />
            }

            <Form.Group widths="equal">
              <Form.Field>
                <Checkbox
                  name="hasPickupWindow"
                  label="Has pickup window?"
                  checked={values.hasPickupWindow}
                  onChange={(e, { checked }) => setFieldValue('hasPickupWindow', checked)}
                />
              </Form.Field>
              <Form.Field>
                <Checkbox
                  name="open24Hours"
                  label="Open 24 hours?"
                  checked={values.open24Hours}
                  onChange={(e, { checked }) => setFieldValue('open24Hours', checked)}
                />
              </Form.Field>
            </Form.Group>

            <Divider />

            <Button color="teal" disabled={isSubmitting || !isValid} floated="right">
              Save
            </Button>
          </Form>
        </React.Fragment>
      )}
    />
  </Segment>
);

LocationEditForm.propTypes = {
  name: PropTypes.string,
  displayName: PropTypes.string,
  description: PropTypes.string,
  onlineOrderUrl: PropTypes.string,
  phone: PropTypes.string,
  hasPickupWindow: PropTypes.bool,
  open24Hours: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

LocationEditForm.defaultProps = {
  name: '',
  displayName: '',
  description: '',
  onlineOrderUrl: '',
  phone: '',
  hasPickupWindow: false,
  open24Hours: false,
};

export default LocationEditForm;
