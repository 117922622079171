import React from 'react';
import ReactDom from 'react-dom';
import { Provider } from 'react-redux';
import { hot } from 'react-hot-loader'; // eslint-disable-line import/no-extraneous-dependencies
import { Route } from 'react-router-dom';
import { ConnectedRouter } from 'react-router-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { OidcProvider } from 'redux-oidc';
import { ApolloProvider } from 'react-apollo';
import App from '@/app';
import apolloClient from '@/common/apollo-client';
import { history, persistor, sagaMiddleware, store } from '@/app/store';
import userManager from '@/common/utils/user-manager';
import sagas from '@/app/sagas';

import 'hammerjs';

import '@progress/kendo-theme-default/dist/all.css';

sagaMiddleware.run(sagas);

const render = (Component) => {
  ReactDom.render(
    <ApolloProvider client={apolloClient}>
      <Provider store={store}>
        <OidcProvider store={store} userManager={userManager}>
          <PersistGate persistor={persistor}>
            <ConnectedRouter history={history}>
              <Route path="/" component={Component} />
            </ConnectedRouter>
          </PersistGate>
        </OidcProvider>
      </Provider>
    </ApolloProvider>,
    document.getElementById('root'),
  );
};

render(hot(module)(App));
