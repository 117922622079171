import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Yup from 'yup';
import { Button, Divider, Form, Header, Message, Segment } from 'semantic-ui-react/dist/es';

const ManagerEditForm = ({
  name,
  email,
  onSubmit,
  isSubmitting,
}) => (
  <Formik
    enableReinitialize
    initialValues={{
      name: name || '',
      email: email || '',
    }}
    validationSchema={Yup.object().shape({
      name: Yup.string()
        .required('Name is required!')
        .max(250, 'Name must not exceed 250 characters!'),
      email: Yup.string()
        .required('Email is required!')
        .email('Email must be a valid email!')
        .max(250, 'Email must not exceed 250 characters!'),
    })}
    onSubmit={values => onSubmit(values)}
    render={({
      values, errors, touched, handleChange, handleBlur, handleSubmit, isValid,
    }) => (
      <Segment clearing>
        <Header size="medium">Manager</Header>

        <Form onSubmit={handleSubmit} error={!!errors} loading={isSubmitting}>
          <Form.Input
            name="name"
            label="Name"
            placeholder="Name"
            value={values.name}
            error={touched.name && !!errors.name}
            onBlur={handleBlur}
            onChange={handleChange}
            required
          />

          {errors &&
            errors.name &&
            touched.name && <Message negative content={errors.name} size="mini" />}

          <Form.Input
            name="email"
            label="Email"
            placeholder="Email"
            value={values.email}
            error={touched.email && !!errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
            required
          />

          {errors &&
            errors.email &&
            touched.email && <Message negative content={errors.email} size="mini" />}

          <Divider />

          <Button color="teal" disabled={isSubmitting || !isValid} floated="right">
            Save
          </Button>
        </Form>
      </Segment>
    )}
  />
);

ManagerEditForm.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

ManagerEditForm.defaultProps = {
  name: '',
  email: '',
};

export default ManagerEditForm;
