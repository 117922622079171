import React from 'react';
import PropTypes from 'prop-types';
import PlacesAutocomplete from 'react-places-autocomplete';

import LocationPickerSuggestion from './LocationPickerSuggestion';
import LocationPickerFooter from './LocationPickerFooter';
import './LocationPicker.css';

const cssClasses = {
  root: 'field',
  input: 'Demo__search-input',
  autocompleteContainer: 'Demo__autocomplete-container',
};

const shouldFetchSuggestions = ({ value }) => value.length > 2;

const onError = (status, clearSuggestions) => {
  // eslint-disable-next-line no-console
  console.log(
    'Error happened while fetching suggestions from Google Maps API',
    status,
  );

  clearSuggestions();
};

const LocationPicker = ({ address, handleChange, handleSelect }) => {
  const inputProps = {
    type: 'text',
    value: address,
    onChange: handleChange,
    autoFocus: true,
    placeholder: 'Search Places',
    name: 'Demo__input',
    id: 'my-input-id',
  };

  return (
    <PlacesAutocomplete
      renderSuggestion={LocationPickerSuggestion}
      renderFooter={LocationPickerFooter}
      inputProps={inputProps}
      classNames={cssClasses}
      onSelect={handleSelect}
      onEnterKeyDown={handleSelect}
      onError={onError}
      shouldFetchSuggestions={shouldFetchSuggestions}
    />
  );
};

LocationPicker.propTypes = {
  address: PropTypes.string,
  handleSelect: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
};

LocationPicker.defaultProps = {
  address: '',
};

export default LocationPicker;
