import React from 'react';
import 'react-dates/initialize';
import { Route, Switch } from 'react-router-dom';
import { injectGlobal } from 'styled-components';
import { ToastContainer, toast } from 'react-toastify';
import 'semantic-ui-css/semantic.min.css';
import 'react-dates/lib/css/_datepicker.css';
import 'react-times/css/material/default.css';

import configureToasts from '@/app/config/toast-config';
// import NotFound from '@/common/components/NotFound';
import Logout from '@/features/logout';
import ModalManager from '@/features/modals/ModalManager';
import ContextMenuManager from '@/features/context-menus/ContextMenuManager';
import AuthCallback from '@/app/callback';
import Auth from './Auth';

injectGlobal`
  * {
    box-sizing: border-box;
  }

  body {
    min-height: 100vh;
    background-color: #efefef;
    overflow-y: scroll;
  }

  #root {
    min-height: 100vh;
  }

  .DayPicker_weekHeaders {
    padding-bottom: 5px;
  }

  .ui.form input[type=text].DateInput_input {
    border: none;
    border-radius:0;
  }
`;

configureToasts();

const App = () => (
  <React.Fragment>
    <ModalManager />
    <ContextMenuManager />
    <ToastContainer autoClose={3000} position={toast.POSITION.BOTTOM_RIGHT} />

    <Switch>
      <Route path="/callback" exact component={AuthCallback} />
      <Route path="/logout" exact component={Logout} />
      <Route path="/" component={Auth} />
      {/* <Route path="*" component={NotFound} /> */}
    </Switch>
  </React.Fragment>
);

export default App;
