import storage from 'redux-persist/lib/storage';

// TODO: Rename key based on app name
const persistConfig = {
  key: 'hello-bistro',
  version: 1,
  storage,
  blacklist: ['requesting'],
};

export default persistConfig;
