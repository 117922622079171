import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { Grid, Icon } from 'semantic-ui-react/dist/es';

const transformContentTypes = contentTypes => contentTypes.join(', ');

const FileUpload = ({ guidance, contentTypes, onUpload }) => (
  <Dropzone
    multiple={false}
    accept={transformContentTypes(contentTypes)}
    onDrop={onUpload}
    style={{ border: 'none' }}
  >
    <Grid textAlign="center" verticalAlign="middle">
      <Grid.Column>
        <Icon name="upload" size="big" />
        <p>{guidance}</p>
      </Grid.Column>
    </Grid>
  </Dropzone>
);

FileUpload.propTypes = {
  guidance: PropTypes.string,
  onUpload: PropTypes.func.isRequired,
  contentTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
};

FileUpload.defaultProps = {
  guidance: 'Click here to upload a file',
};

export default FileUpload;
