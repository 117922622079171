import React from 'react';
import PropTypes from 'prop-types';
import { Image, Menu } from 'semantic-ui-react/dist/es';
import { NavLink } from 'react-router-dom';
import logo from '@/common/assets/brand-logo.png';

const NavBarDesktop = ({ leftItems, rightItems }) => (
  <Menu fixed="top" inverted>
    <Menu.Item>
      <Image size="small" src={logo} />
    </Menu.Item>
    {leftItems.map(item => (<Menu.Item as={NavLink} {...item} />))}
    <Menu.Menu position="right">
      {rightItems.map(item => (<Menu.Item as={NavLink} {...item} />))}
    </Menu.Menu>
  </Menu>
);

NavBarDesktop.propTypes = {
  leftItems: PropTypes.arrayOf(PropTypes.shape).isRequired,
  rightItems: PropTypes.arrayOf(PropTypes.shape).isRequired,
};

export default NavBarDesktop;
