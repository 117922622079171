import React from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'semantic-ui-react/dist/es';

const TestContextMenu = ({ text }) => (
  <Menu vertical>
    <Menu.Item>
      <Menu.Header>Menu Header: {text} </Menu.Header>

      <Menu.Menu>
        <Menu.Item>First Menu Item</Menu.Item>
        <Menu.Item>Second Menu Item</Menu.Item>
      </Menu.Menu>
    </Menu.Item>
  </Menu>
);

TestContextMenu.propTypes = {
  text: PropTypes.string.isRequired,
};

export default TestContextMenu;
