import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Grid } from 'semantic-ui-react/dist/es';

import api from '@/common/api';
import { history } from '@/app/store';

import LocationGroupMemberForm from '@/features/location-groups/LocationGroupMemberForm';
import LocationGroupEditForm from '@/features/location-groups/LocationGroupEditForm';

const UPDATE_SUCCESS = 'Successfully updated location group!';
const UPDATE_ERROR = 'Error updating location group!';

class LocationGroupEditPage extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string,
      }).isRequired,
    }).isRequired,
  };

  state = {
    loading: true,
    group: {},
    locations: [],
    selectedLocations: [],
  };

  async componentDidMount() {
    const { id } = this.props.match.params;

    try {
      let group = {};
      if (id) {
        ({ data: group } = await api.get(`/api/locationgroup/${id}`));
      }

      const { data: locations } = await api.get('api/location');

      this.setState({
        loading: false,
        group,
        locations,
        selectedLocations: (group && group.locationIds) || [],
      });
    } catch (e) {
      toast.error(UPDATE_ERROR);
    }
  }

  handleSubmit = (group) => {
    const { id } = this.props.match.params;

    this.setState({ loading: true }, async () => {
      try {
        const promise = id ?
          api.put(`/api/locationgroup/${id}`, group)
          : api.post('/api/locationgroup', group);

        const response = await promise;
        toast.success(UPDATE_SUCCESS);
        if (!id) {
          history.push(`/location-groups/${response.data.id}`);
        }
      } catch (e) {
        toast.error(UPDATE_ERROR);
      }

      this.setState({ loading: false });
    });
  };

  handleLocationChange = (e, { value }) => {
    this.setState({ selectedLocations: value });
  };

  handleLocationUpdate = ({ selectedLocations }) => {
    const { id } = this.props.match.params;

    this.setState({ loading: true }, async () => {
      try {
        await api.put(`/api/locationgroup/${id}/membership`, {
          locationIds: selectedLocations,
        });

        toast.success(UPDATE_SUCCESS);
      } catch (e) {
        toast.error(UPDATE_ERROR);
      }

      this.setState({ loading: false });
    });
  };

  render() {
    const { id } = this.props.match.params;

    return (
      <Grid stackable columns="equal">
        <Grid.Column>
          <LocationGroupEditForm
            {...this.state.group}
            onSubmit={this.handleSubmit}
            isSubmitting={this.state.loading}
          />
          {id && (
            <LocationGroupMemberForm
              locations={this.state.locations}
              selectedLocations={this.state.selectedLocations}
              handleLocationChange={this.handleLocationChange}
              onSubmit={this.handleLocationUpdate}
              isSubmitting={this.state.loading}
            />
          )}
        </Grid.Column>
      </Grid>
    );
  }
}

export default withRouter(LocationGroupEditPage);
