import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router-dom';

import AppInstallsPage from './pages/AppInstallsPage';
import ConsumerActionsPage from './pages/ConsumerActionsPage';

const Reports = ({ match }) => (
  <Switch>
    <Route path={`${match.url}/appinstalls`} exact component={AppInstallsPage} />
    <Route path={`${match.url}/consumeractions`} exact component={ConsumerActionsPage} />
  </Switch>
);

Reports.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(Reports);
