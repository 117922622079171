import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Yup from 'yup';
import { Button, Divider, Form, Header, Message, Segment } from 'semantic-ui-react/dist/es';

import LocationPicker from '@/common/components/LocationPicker';

const stateOptions = [
  {
    key: 'AL',
    text: 'Alabama',
    value: 'AL',
  },
  {
    key: 'AK',
    text: 'Alaska',
    value: 'AK',
  },
  {
    key: 'AZ',
    text: 'Arizona',
    value: 'AZ',
  },
  {
    key: 'AR',
    text: 'Arkansas',
    value: 'AR',
  },
  {
    key: 'CA',
    text: 'California',
    value: 'CA',
  },
  {
    key: 'CO',
    text: 'Colorado',
    value: 'CO',
  },
  {
    key: 'CT',
    text: 'Connecticut',
    value: 'CT',
  },
  {
    key: 'DE',
    text: 'Delaware',
    value: 'DE',
  },
  {
    key: 'DC',
    text: 'District of Columbia',
    value: 'DC',
  },
  {
    key: 'FL',
    text: 'Florida',
    value: 'FL',
  },
  {
    key: 'GA',
    text: 'Georgia',
    value: 'GA',
  },
  {
    key: 'HI',
    text: 'Hawaii',
    value: 'HI',
  },
  {
    key: 'ID',
    text: 'Idaho',
    value: 'ID',
  },
  {
    key: 'IL',
    text: 'Illinois',
    value: 'IL',
  },
  {
    key: 'IN',
    text: 'Indiana',
    value: 'IN',
  },
  {
    key: 'IA',
    text: 'Iowa',
    value: 'IA',
  },
  {
    key: 'KS',
    text: 'Kansas',
    value: 'KS',
  },
  {
    key: 'KY',
    text: 'Kentucky',
    value: 'KY',
  },
  {
    key: 'LA',
    text: 'Louisiana',
    value: 'LA',
  },
  {
    key: 'ME',
    text: 'Maine',
    value: 'ME',
  },
  {
    key: 'MD',
    text: 'Maryland',
    value: 'MD',
  },
  {
    key: 'MA',
    text: 'Massachusetts',
    value: 'MA',
  },
  {
    key: 'MI',
    text: 'Michigan',
    value: 'MI',
  },
  {
    key: 'MN',
    text: 'Minnesota',
    value: 'MN',
  },
  {
    key: 'MS',
    text: 'Mississippi',
    value: 'MS',
  },
  {
    key: 'MO',
    text: 'Missouri',
    value: 'MO',
  },
  {
    key: 'MT',
    text: 'Montana',
    value: 'MT',
  },
  {
    key: 'NE',
    text: 'Nebraska',
    value: 'NE',
  },
  {
    key: 'NV',
    text: 'Nevada',
    value: 'NV',
  },
  {
    key: 'NH',
    text: 'New Hampshire',
    value: 'NH',
  },
  {
    key: 'NJ',
    text: 'New Jersey',
    value: 'NJ',
  },
  {
    key: 'NM',
    text: 'New Mexico',
    value: 'NM',
  },
  {
    key: 'NY',
    text: 'New York',
    value: 'NY',
  },
  {
    key: 'NC',
    text: 'North Carolina',
    value: 'NC',
  },
  {
    key: 'ND',
    text: 'North Dakota',
    value: 'ND',
  },
  {
    key: 'OH',
    text: 'Ohio',
    value: 'OH',
  },
  {
    key: 'OK',
    text: 'Oklahoma',
    value: 'OK',
  },
  {
    key: 'OR',
    text: 'Oregon',
    value: 'OR',
  },
  {
    key: 'PA',
    text: 'Pennsylvania',
    value: 'PA',
  },
  {
    key: 'RI',
    text: 'Rhode Island',
    value: 'RI',
  },
  {
    key: 'SC',
    text: 'South Carolina',
    value: 'SC',
  },
  {
    key: 'SD',
    text: 'South Dakota',
    value: 'SD',
  },
  {
    key: 'TN',
    text: 'Tennessee',
    value: 'TN',
  },
  {
    key: 'TX',
    text: 'Texas',
    value: 'TX',
  },
  {
    key: 'UT',
    text: 'Utah',
    value: 'UT',
  },
  {
    key: 'VT',
    text: 'Vermont',
    value: 'VT',
  },
  {
    key: 'VA',
    text: 'Virginia',
    value: 'VA',
  },
  {
    key: 'WA',
    text: 'Washington',
    value: 'WA',
  },
  {
    key: 'WV',
    text: 'West Virginia',
    value: 'WV',
  },
  {
    key: 'WI',
    text: 'Wisconsin',
    value: 'WI',
  },
  {
    key: 'WY',
    text: 'Wyoming',
    value: 'WY',
  },
];

const LocationAddressForm = ({
  address1,
  address2,
  city,
  state,
  zipCode,
  latitude,
  longitude,
  geoLocationLookupAddress,
  onSubmit,
  isSubmitting,
  handleGeocode,
}) => (
  <Formik
    enableReinitialize
    initialValues={{
      address1: address1 || '',
      address2: address2 || '',
      city: city || '',
      state: state || '',
      zipCode: zipCode || '',
      latitude: latitude || '',
      longitude: longitude || '',
      geoLocationLookupAddress: geoLocationLookupAddress || '',
    }}
    validationSchema={Yup.object().shape({
      address1: Yup.string().required('Address is required!'),
      address2: Yup.string(),
      city: Yup.string().required('City is required!'),
      state: Yup.string().required('State is required!'),
      zipCode: Yup.string().required('Zip code is required!'),
      latitude: Yup.number().required('Latitude is required!'),
      longitude: Yup.number().required('Longitude is required!'),
      geoLocationLookupAddress: Yup.string(),
    })}
    onSubmit={values => onSubmit(values)}
    render={({
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      // isValid,
      setFieldTouched,
      setFieldValue,
    }) => (
      <Segment clearing>
        <Header size="medium">Location</Header>

        <Form onSubmit={handleSubmit} error={!!errors} loading={isSubmitting}>
          <Form.Input
            name="address1"
            label="Address"
            placeholder="Address"
            value={values.address1}
            error={touched.address1 && !!errors.address1}
            onBlur={handleBlur}
            onChange={handleChange}
            required
          />
          <Form.Input
            name="address2"
            value={values.address2}
            error={touched.address2 && !!errors.address2}
            onBlur={handleBlur}
            onChange={handleChange}
          />

          <Form.Group>
            <Form.Input
              name="city"
              label="City"
              placeholder="City"
              value={values.city}
              error={touched.city && !!errors.city}
              onBlur={handleBlur}
              onChange={handleChange}
              required
            />
            <Form.Dropdown
              name="state"
              label="State"
              placeholder="State"
              options={stateOptions}
              error={touched.state && !!errors.state}
              value={values.state}
              onBlur={() => setFieldTouched('state', true)}
              onChange={(e, { value }) => setFieldValue('state', value)}
              selection
              required
            />
            <Form.Input
              name="zipCode"
              label="Zip Code"
              placeholder="12345"
              value={values.zipCode}
              error={touched.zipCode && !!errors.zipCode}
              onBlur={handleBlur}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <p>
            Search and choose a location using the textbox below.
            Upon selection, latitude and longitude will be calculated automatically.
          Once the latitude and longitude fields are populated, click the {' '}
            the <strong>Save</strong> button to commit the changes.
          </p>

          <LocationPicker
            address={values.geoLocationLookupAddress}
            handleChange={addr => setFieldValue('geoLocationLookupAddress', addr)}
            handleSelect={addr => handleGeocode(addr)}
          />

          {
            errors && errors.geoLocationLookupAddress && touched.geoLocationLookupAddress &&
            <Message negative content={errors.geoLocationLookupAddress} size="mini" />
          }

          <Divider />
          <Form.Group widths="equal">
            <Form.Input
              name="latitude"
              label="Latitude"
              placeholder="Latitude"
              value={values.latitude}
              error={touched.latitude && !!errors.latitude}
              onBlur={handleBlur}
              onChange={handleChange}
              readOnly
            />

            <Form.Input
              name="longitude"
              label="Longitude"
              placeholder="Longitude"
              value={values.longitude}
              error={touched.longitude && !!errors.longitude}
              onBlur={handleBlur}
              onChange={handleChange}
              readOnly
            />
          </Form.Group>

          {
            errors && errors.latitude && touched.latitude &&
            <Message negative content={errors.latitude} size="mini" />
          }

          {
            errors && errors.longitude && touched.longitude &&
            <Message negative content={errors.longitude} size="mini" />
          }

          <Divider />

          <Button color="teal" floated="right">
            Save
          </Button>
        </Form>
      </Segment>
    )}
  />
);

LocationAddressForm.propTypes = {
  address1: PropTypes.string,
  address2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zipCode: PropTypes.string,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  geoLocationLookupAddress: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  handleGeocode: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

LocationAddressForm.defaultProps = {
  address1: '',
  address2: '',
  city: '',
  state: '',
  zipCode: '',
  latitude: null,
  longitude: null,
  geoLocationLookupAddress: '',
};

export default LocationAddressForm;
