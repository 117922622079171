import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Image, Menu } from 'semantic-ui-react/dist/es';
import { NavLink } from 'react-router-dom';
import logo from '@/common/assets/brand-logo.png';

const NavBarMobile = ({ children, items }) => (
  <React.Fragment>
    <Menu fixed="top" inverted>
      <Menu.Item>
        <Image size="small" src={logo} />
      </Menu.Item>

      <Dropdown item simple text="Admin">
        <Dropdown.Menu>{items.map(item => <Dropdown.Item as={NavLink} {...item} />)}</Dropdown.Menu>
      </Dropdown>
    </Menu>
    {children}
  </React.Fragment>
);

NavBarMobile.propTypes = {
  children: PropTypes.node.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape).isRequired,
};

export default NavBarMobile;
