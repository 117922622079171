import React from 'react';
import { Route, withRouter } from 'react-router-dom';

import ConsumerActionsContainer from '@/features/reports/ConsumerActions/ConsumerActionsContainer';

const ConsumerActionsPage = () => (
  <Route component={ConsumerActionsContainer} />
);

export default withRouter(ConsumerActionsPage);
