import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router-dom';

import ManagersPage from './pages/ManagersPage';
import ManagerEditPage from './pages/ManagerEditPage';

const Managers = ({ match }) => (
  <Switch>
    <Route path={`${match.url}`} exact component={ManagersPage} />
    <Route path={`${match.url}/create`} exact component={ManagerEditPage} />
    <Route path={`${match.url}/:id`} exact component={ManagerEditPage} />
  </Switch>
);

Managers.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(Managers);
