import axios from 'axios';
import { store } from '@/app/store';
import { getTokenFromStore } from '@/common/utils/store-utils';
import getConfig from '@/config/getConfig';

const api = axios.create({
  baseURL: `${getConfig().EATNPARK_API_URL}`,
});

api.interceptors.request.use((config) => {
  const token = getTokenFromStore(store);
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = token;

  return config;
});

export default api;
