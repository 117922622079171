import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Grid } from 'semantic-ui-react/dist/es';
import { Mutation } from 'react-apollo';
import moment from 'moment';

import OperatingHoursEditForm from '@/features/hours-of-operation/OperatingHoursEditForm';
import OperatingHoursDetailEditForm from '@/features/hours-of-operation/OperatingHoursDetailEditForm';
import OperatingHoursDetailList from '@/features/hours-of-operation/OperatingHoursDetailList';
import { OPERATING_HOURS_DETAIL_QUERY } from '@/features/hours-of-operation/queries';
import { CREATE_SCHEDULE_DETAIL, DELETE_SCHEDULE_DETAIL } from '@/features/hours-of-operation/mutations';

import {
  fetchHoursRequest,
  updateHoursRequest,
} from '@/features/hours-of-operation/actions';

class OperatingHoursEditPage extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string,
        hoursId: PropTypes.string,
      }).isRequired,
    }).isRequired,
    loading: PropTypes.bool.isRequired,
    schedule: PropTypes.shape().isRequired,
    updateHoursRequest: PropTypes.func.isRequired,
    fetchHoursRequest: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { id, hoursId } = this.props.match.params;
    this.props.fetchHoursRequest(id, hoursId);
  }

  handleUpdate = (hours) => {
    const { id, hoursId } = this.props.match.params;
    this.props.updateHoursRequest(id, hoursId, hours);
  };

  render() {
    const { loading, match, schedule } = this.props;
    /* eslint-disable no-unused-vars */
    return (
      <React.Fragment>
        <OperatingHoursEditForm
          {...schedule}
          isSubmitting={loading}
          onSubmit={this.handleUpdate}
        />
        <Grid stackable columns="equal">
          <Grid.Column>
            <Mutation
              mutation={CREATE_SCHEDULE_DETAIL}
              update={(cache, { data: { createScheduleDetail: { detail } } }) => {
                const variables = {
                  id: match.params.id,
                  hoursSearch: {
                    filters: `id==${match.params.hoursId}`,
                  },
                  hoursDetailsSearch: {
                    sorts: 'dayId',
                  },
                };

                const { location } = cache.readQuery({
                  query: OPERATING_HOURS_DETAIL_QUERY,
                  variables,
                });

                location.hours[0].details = location.hours[0].details || [];
                const newOpenTime = moment.duration(detail.open);

                // insert new record in sorted order by day then time
                let inserted = false;
                for (let i = 0, len = location.hours[0].details.length; i < len; i += 1) {
                    if (detail.dayId < location.hours[0].details[i].dayId
                      || (detail.dayId === location.hours[0].details[i].dayId
                        && newOpenTime < moment.duration(location.hours[0].details[i].open))) {
                        location.hours[0].details.splice(i, 0, detail);
                        inserted = true;
                        break;
                    }
                }

                if (!inserted) {
                  location.hours[0].details = location.hours[0].details.concat([detail]);
                }

                cache.writeQuery({
                  query: OPERATING_HOURS_DETAIL_QUERY,
                  data: { location },
                  variables,
                });
              }}
            >
              {
                createScheduleDetail => (
                  <OperatingHoursDetailEditForm
                    {...schedule}
                    isSubmitting={loading}
                    onSubmit={({ dayId, open, duration }) => {
                      createScheduleDetail({
                        variables: {
                          input: {
                            masterId: this.props.match.params.hoursId,
                            dayId,
                            open,
                            duration,
                          },
                        },
                      });
                    }}
                  />
                )
              }
            </Mutation>
          </Grid.Column>
          <Grid.Column>
            <Mutation
              mutation={DELETE_SCHEDULE_DETAIL}
              update={(cache, { data: { deleteScheduleDetail } }) => {
                const variables = {
                  id: match.params.id,
                  hoursSearch: {
                    filters: `id==${match.params.hoursId}`,
                  },
                  hoursDetailsSearch: {
                    sorts: 'dayId',
                  },
                };

                const { location } = cache.readQuery({
                  query: OPERATING_HOURS_DETAIL_QUERY,
                  variables,
                });

                location.hours[0].details = [
                  ...location.hours[0].details.filter(d => d.id !== deleteScheduleDetail.id),
                ];

                cache.writeQuery({
                  query: OPERATING_HOURS_DETAIL_QUERY,
                  data: { location },
                  variables,
                });
              }}
            >
              {
                deleteScheduleDetail => (
                  <OperatingHoursDetailList handleDelete={(id) => {
                      deleteScheduleDetail({
                        variables: {
                          input: {
                            id,
                          },
                        },
                      });
                    }}
                  />
                )
              }
            </Mutation>
          </Grid.Column>
        </Grid>

      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.hours.requesting,
  schedule: state.hours.selectedSchedule,
});

const actions = {
  fetchHoursRequest,
  updateHoursRequest,
};

export default withRouter(connect(mapStateToProps, actions)(OperatingHoursEditPage));

