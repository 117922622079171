import moment from 'moment';

moment.fn.setTime = function setTime(str, format) {
  const timeParsed = moment(str, format);
  if (timeParsed.isValid()) {
    return this.hour(timeParsed.hour())
      .minute(timeParsed.minute())
      .second(timeParsed.second());
  }

  // eslint-disable-next-line no-console
  console.warning(`Time couldn't be parsed: ${str} (format: ${format})`);
  return this;
};

export const getMomentFromDateString = (datetime) => {
  const deliveryDate = (datetime && moment(datetime)) || null;
  return deliveryDate;
};

export const getTimeFromDateString = (datetime) => {
  const m = getMomentFromDateString(datetime);
  if (!m) {
    return null;
  }

  return m.format('hh:mm');
};

export const getMeridiemFromDateString = (datetime) => {
  const m = getMomentFromDateString(datetime);
  if (!m) {
    return null;
  }

  return m.format('A');
};

const friendlyDateTimeFormat = 'MMM D YYYY h:mm A';
export const getFormattedDateString = (datetime) => {
  const m = getMomentFromDateString(datetime);
  if (!m) {
    return '';
  }

  return m.format(friendlyDateTimeFormat);
};

const combineDateAndTime = (momentDate, time, meridiem) => {
  if (!momentDate || !time || !meridiem) {
    return null;
  }

  const combinedMoment = momentDate.setTime(`${time} ${meridiem}`, 'h:mm A');
  return combinedMoment;
};

export const getIsoFormattedString = (momentDate, time, meridiem) => {
  const m = combineDateAndTime(momentDate, time, meridiem);
  if (!m) {
    return null;
  }

  return m.format();
};

export const DEFAULT_START_TIME = '12:00';
export const DEFAULT_START_MERIDIEM = 'PM';
export const DEFAULT_END_TIME = '5:00';
export const DEFAULT_END_MERIDIEM = 'PM';

export const isInPast = (momentDate) => {
  if (!momentDate) {
    return false;
  }

  return moment().isAfter(momentDate);
};

export const getTwentyFourHourTime = (datestring) => {
  const d = new Date(`1/1/2013  ${datestring}`);
  return `${d.getHours()}:${d.getMinutes()}`;
};

export const calculateTimeDifference = (startTime, endTime) => {
  const startTimeArray = startTime.split(':');
  const startInputHrs = parseInt(startTimeArray[0], 10);
  const startInputMins = parseInt(startTimeArray[1], 10);

  const endTimeArray = endTime.split(':');
  const endInputHrs = parseInt(endTimeArray[0], 10);
  const endInputMins = parseInt(endTimeArray[1], 10);

  const startMin = (startInputHrs * 60) + startInputMins;
  const endMin = (endInputHrs * 60) + endInputMins;

  let result;

  if (endMin < startMin) {
    const minutesPerDay = 24 * 60;
    result = minutesPerDay - startMin; // minutes til midnight
    result += endMin; // minutes in the next day
  } else {
    result = endMin - startMin;
  }

  const minutesElapsed = result % 60;
  const hoursElapsed = (result - minutesElapsed) / 60;

  const minutesElapsedDisplay = minutesElapsed < 10 ? `0${minutesElapsed}` : minutesElapsed;

  return `${hoursElapsed}:${minutesElapsedDisplay}`;
};
