import React from 'react';
import PropTypes from 'prop-types';

const AbsolutePosition = (props) => {
  const { children, nodeRef } = props;
  const style = {
    position: 'absolute',
    top: props.top,
    bottom: props.bottom,
    left: props.left,
    right: props.right,
    width: props.width,
  };

  return (
    <div style={style} className={props.className} ref={nodeRef}>
      {children}
    </div>
  );
};

AbsolutePosition.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
  top: PropTypes.number.isRequired,
  bottom: PropTypes.number,
  left: PropTypes.number.isRequired,
  right: PropTypes.number,
  width: PropTypes.number,
  nodeRef: PropTypes.func.isRequired,
};

AbsolutePosition.defaultProps = {
  bottom: null,
  right: null,
  width: null,
};

export default AbsolutePosition;
