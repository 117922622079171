import React from 'react';
import PropTypes from 'prop-types';
import { Responsive } from 'semantic-ui-react/dist/es';
import NavBarChildren from './NavBarChildren';
import NavBarMobile from './NavBarMobile';
import NavBarDesktop from './NavBarDesktop';

const NavBar = ({ children, leftItems, rightItems }) => (
  <React.Fragment>
    <Responsive {...Responsive.onlyMobile}>
      <NavBarMobile items={[...leftItems, ...rightItems]}>
        <NavBarChildren>{children}</NavBarChildren>
      </NavBarMobile>
    </Responsive>
    <Responsive minWidth={Responsive.onlyTablet.minWidth}>
      <NavBarDesktop leftItems={leftItems} rightItems={rightItems} />
      <NavBarChildren>{children}</NavBarChildren>
    </Responsive>
  </React.Fragment>
);

NavBar.propTypes = {
  children: PropTypes.node.isRequired,
  leftItems: PropTypes.arrayOf(PropTypes.shape).isRequired,
  rightItems: PropTypes.arrayOf(PropTypes.shape).isRequired,
};

export default NavBar;
