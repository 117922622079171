import getConfig from '@/config/getConfig';
import { createUserManager } from 'redux-oidc';

const config = getConfig();
const userManagerConfig = {
  client_id: config.ASPIRANT_IDENTITYSERVER_CLIENT_NAME,
  redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/callback`,
  response_type: 'token id_token',
  scope: `openid profile ${config.ASPIRANT_IDENTITYSERVER_API_NAME}`,
  authority: config.ASPIRANT_IDENTITYSERVER_URL,
  silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/silent_renew.html`,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
};

const userManager = createUserManager(userManagerConfig);

export default userManager;
