import { createStore, applyMiddleware } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import { composeWithDevTools } from 'redux-devtools-extension'; // eslint-disable-line import/no-extraneous-dependencies
import { createBrowserHistory } from 'history';
import { persistReducer, persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import createOidcMiddleware, { loadUser } from 'redux-oidc';
import userManager from '@/common/utils/user-manager';
import reducers from '@/app/reducers/root-reducer';
import persistConfig from '@/app/config/persist-config';

export const history = createBrowserHistory();
export const sagaMiddleware = createSagaMiddleware();
const oidcMiddleware = createOidcMiddleware(userManager);
const middlewares = [
  sagaMiddleware,
  routerMiddleware(history),
  oidcMiddleware,
];

const middlewareEnhancer = applyMiddleware(...middlewares);
const storeEnhancers = [middlewareEnhancer];
const composedEnhancer = composeWithDevTools(...storeEnhancers);

export const reducer = persistReducer(persistConfig, reducers);
export const store = createStore(reducer, composedEnhancer);
loadUser(store, userManager);
export const persistor = persistStore(store);
