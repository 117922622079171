import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Icon, Input, Menu } from 'semantic-ui-react/dist/es';
import { debounce } from 'lodash-es';

import MessagesList from '@/features/messages/MessagesList';

const MessagesMenu = ({ onSearchChange }) => (
  <Menu stackable borderless secondary>
    <Menu.Item>
      <Button as={Link} to="/messages/create" icon primary size="mini" labelPosition="left">
        <Icon name="add" />
        Create Message
      </Button>
    </Menu.Item>
    <Menu.Item position="right">
      <Input
        className="icon"
        icon="search"
        placeholder="Search..."
        onChange={onSearchChange}
      />
    </Menu.Item>
  </Menu>
);

MessagesMenu.propTypes = {
  onSearchChange: PropTypes.func.isRequired,
};

class MessagesPage extends React.Component {
  state = {
    keyword: '',
  }

  handleSearchChange = debounce((e, data) => {
    this.setState({
      keyword: data.value,
    });
  }, 500);

  render() {
    return (
      <React.Fragment>
        <MessagesMenu onSearchChange={this.handleSearchChange} />
        <MessagesList keyword={this.state.keyword} />
      </React.Fragment>
    );
  }
}

export default MessagesPage;
