import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router-dom';
import LocationsPage from './pages/LocationsPage';
import LocationCreatePage from './pages/LocationCreatePage';
import LocationPage from './pages/LocationPage';

const Locations = ({ match }) => (
  <Switch>
    <Route path={`${match.url}`} exact component={LocationsPage} />
    <Route path={`${match.url}/create`} exact component={LocationCreatePage} />
    <Route path={`${match.url}/:id`} component={LocationPage} />
  </Switch>
);

Locations.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(Locations);
