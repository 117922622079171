import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'semantic-ui-react/dist/es';

const StatusToggle = ({ enabled, loading, onClick }) => {
  if (enabled) {
    return (
      <Button fluid positive loading={loading} disabled={loading} onClick={onClick}>
        <Icon name="checkmark" />Enabled
      </Button>
    );
  }

  return (
    <Button fluid negative loading={loading} disabled={loading} onClick={onClick}>
      <Icon name="remove" />
      Disabled
    </Button>
  );
};

StatusToggle.propTypes = {
  enabled: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

StatusToggle.defaultProps = {
  enabled: false,
  loading: false,
};

export default StatusToggle;
