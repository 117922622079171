import { all } from 'redux-saga/effects';

import LocationSaga from '@/features/locations/sagas';
import OperatingHoursSaga from '@/features/hours-of-operation/sagas';

export default function* () {
  yield all([
    LocationSaga(),
    OperatingHoursSaga(),
  ]);
}
