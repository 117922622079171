import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as oidcReducer } from 'redux-oidc';

import location from '@/features/locations/reducer';
import hours from '@/features/hours-of-operation/reducer';
import contextMenu from '@/features/context-menus/reducer';
import modals from '@/features/modals/reducer';

export default combineReducers({
  contextMenu,
  location,
  hours,
  modals,
  oidc: oidcReducer,
  router: routerReducer,
});
