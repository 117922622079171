import React from 'react';
import PropTypes from 'prop-types';
import { Button, Header, Icon, Loader, Segment, Table } from 'semantic-ui-react/dist/es';
import { withRouter } from 'react-router-dom';
import { Query } from 'react-apollo';
import { isEmpty } from 'lodash-es';

import NoItems from '@/common/components/NoItems';
import { OPERATING_HOURS_DETAIL_QUERY } from '@/features/hours-of-operation/queries';

const OperatingHoursDetailList = ({ match, handleDelete }) => (
  <Query
    query={OPERATING_HOURS_DETAIL_QUERY}
    variables={{
      id: match.params.id,
      hoursSearch: {
        filters: `id==${match.params.hoursId}`,
      },
      hoursDetailsSearch: {
        sorts: 'dayId',
      },
    }}
  >
    {({ data: { location }, loading }) => {
      if (loading) {
        return <Loader active />;
      }

      const { hours } = location;
      const details = (hours && hours.length && hours[0].details) || [];

      if (isEmpty(details)) {
        return (
          <Segment>
            <Header size="medium">Daily Schedule</Header>
            <NoItems />
          </Segment>
        );
      }

      return (
        <Table celled striped selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Day</Table.HeaderCell>
              <Table.HeaderCell>Open</Table.HeaderCell>
              <Table.HeaderCell>Close</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              details.map(({
                id, day, openFormatted, closeFormatted,
              }) => (
                <Table.Row key={id}>
                  <Table.Cell>{day}</Table.Cell>
                  <Table.Cell>{openFormatted}</Table.Cell>
                  <Table.Cell>{closeFormatted}</Table.Cell>
                  <Table.Cell collapsing>
                    <Button compact icon size="mini" negative onClick={() => handleDelete(id)}>
                      <Icon name="remove" />
                    </Button>
                  </Table.Cell>
                </Table.Row>
              ))
            }
          </Table.Body>
        </Table>
      );
    }}
  </Query>
);

OperatingHoursDetailList.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      hoursId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default withRouter(OperatingHoursDetailList);
