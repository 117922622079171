import getConfig from '@/config/getConfig';

const API_URL = `${getConfig().EATNPARK_API_URL}/api/file/image`;

export const getImageUrl = (name) => {
  const imageUrl = `${API_URL}/${name}?width=2500&height=0`;
  return imageUrl;
};

export const getThumbnailUrl = (name) => {
  const imageUrl = `${API_URL}/${name}?width=500&height=0`;
  return imageUrl;
};
