import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { pick } from 'lodash-es';
import Yup from 'yup';
import { Accordion, Button, Divider, Form, Header, Icon, Message, Segment } from 'semantic-ui-react/dist/es';
import { SingleDatePicker } from 'react-dates';
import TimePicker from '@/common/components/TimePicker';
import Multiselect from '@/common/components/Multiselect';
import {
  getMomentFromDateString,
  getTimeFromDateString,
  getMeridiemFromDateString,
  getIsoFormattedString,
} from '@/common/utils/date-utils';

const transformMessageTypes = messageTypes => messageTypes.filter(m => m.name !== 'Survey').map(m => ({
  key: m.id,
  text: m.name,
  value: m.id,
}));

const transformMessageTemplateTypes = messageTypes =>
  messageTypes.filter(m => m.enabled === true).map(m => ({
    key: m.id,
    text: m.name,
    value: m.id,
  }));

const transformLocations = (data) => {
  if (!(data && data.length)) {
    return [];
  }

  return data.map(l => ({
    key: l.id,
    value: l.id,
    text: l.name,
  }));
};

const MessageEditForm = ({
  messageDeliveryTypeId,
  messageDeliveryTypes,
  title,
  body,
  messageTypeId,
  messageTypes,
  dynamicMessageTypeId,
  dynamicMessageTypes,
  reoccurringMessageTypeId,
  reoccurringMessageTypes,
  arisMajor,
  arisMinor,
  onlineCode,
  url,
  disclaimer,
  imageName,
  startDate,
  endDate,
  reoccurringStartDay,
  dynamicConfirmation,
  dynamicSmileyCodeWord,
  templateDeliveryBody,
  templateDeliveryMinutesAfter,
  templateReminderBody,
  templateReminderHoursBefore,
  templateDuration,
  templateAllowDuplicateDays,
  locations,
  locationIds,
  deliveryNotification,
  reminderNotification,
  onSubmit,
  isSubmitting,
}) => {
  const startMoment = getMomentFromDateString(startDate);
  const startTime = getTimeFromDateString(startDate);
  const startMeridiem = getMeridiemFromDateString(startDate);

  const endMoment = getMomentFromDateString(endDate);
  const endTime = getTimeFromDateString(endDate);
  const endMeridiem = getMeridiemFromDateString(endDate);

  const messageTypeOptions = transformMessageTypes(messageTypes);
  const messageDeliveryTypeOptions = transformMessageTypes(messageDeliveryTypes);
  const dynamicTypeOptions = transformMessageTemplateTypes(dynamicMessageTypes);
  const reoccurringTypeOptions = transformMessageTemplateTypes(reoccurringMessageTypes);
  const locationOptions = transformLocations(locations);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        activeIndex: [],
        messageDeliveryTypeId,
        title,
        body,
        messageTypeId,
        dynamicMessageTypeId,
        reoccurringMessageTypeId,
        arisMajor,
        arisMinor,
        onlineCode,
        url,
        disclaimer,
        imageName,
        startMoment,
        startTime: startTime || '8:00',
        startMeridiem: startMeridiem || 'AM',
        endMoment,
        endTime: endTime || '5:00',
        endMeridiem: endMeridiem || 'PM',
        reoccurringStartDay,
        dynamicConfirmation,
        dynamicSmileyCodeWord,
        templateDuration,
        templateAllowDuplicateDays,
        locationIds: locationIds || [],
        deliveryNotification: deliveryNotification || {
          messagePushNotificationTypeId: 1,
          body: templateDeliveryBody || '',
          deliveryMinutesAfter: templateDeliveryMinutesAfter || null,
        },
        reminderNotification: reminderNotification || {
          messagePushNotificationTypeId: 2,
          body: templateReminderBody || '',
          reminderHoursBefore: templateReminderHoursBefore || null,
        },
      }}
      validationSchema={Yup.object().shape({
        messageDeliveryTypeId: Yup.number().required('Message delivery type is required!'),
        title: Yup.string().required('Title is required!').max(100, 'Title must not exceed 100 characters!'),
        body: Yup.string().required('Content is required!').max(500, 'Content must not exceed 500 characters!'),
        startMoment: Yup.date()
          .typeError('Start date is required!')
          .required('Start date is required!'),
        startTime: Yup.string().required('Start time is required!'),
        endMoment: Yup.date()
          .typeError('End date is required!')
          .required('End date is required!'),
        endTime: Yup.string().required('End time is required!'),
        messageTypeId: Yup.number().required('Message type is required!'),
        dynamicMessageTypeId: Yup.number()
          .when('messageDeliveryTypeId', {
            is: val => val === 2,
            then: Yup.number().required('Dynamic message type is required!'),
            otherwise: Yup.number().nullable(true),
          }),
        reoccurringMessageTypeId: Yup.number()
          .when('messageDeliveryTypeId', {
            is: val => val === 3,
            then: Yup.number().required('Reoccurring message type is requried!'),
            otherwise: Yup.number().nullable(true),
          }),
        url: Yup.string()
          .when('messageTypeId', {
            is: val => [3, 4].includes(val),
            then: Yup.string().url('Url must be valid! Try https://').required('Url is required!'),
            otherwise: Yup.string().nullable(true),
          }),
        arisMajor: Yup.string().matches(/\d{2}/, 'ARIS major must be 2 numeric characters.')
          .when('messageTypeId', {
            is: val => val === 5,
            then: Yup.string().required('ARIS code is required!'),
            otherwise: Yup.string().nullable(true),
          }),
        arisMinor: Yup.string().matches(/\d{4}/, 'ARIS minor must be 4 numeric characters.')
          .when('messageTypeId', {
            is: val => val === 5,
            then: Yup.string().required('ARIS code is required!'),
            otherwise: Yup.string().nullable(true),
          }),
        deliveryNotification: Yup.object().shape({
          body: Yup.string()
            .when('deliveryMinutesAfter', {
              is: val => !!val,
              then: Yup.string().required('Delivery notification body is required!'),
              otherwise: Yup.string().nullable(true),
            }),
          deliveryMinutesAfter: Yup.number()
            .when('body', {
              is: val => !!val,
              then: Yup.number().required('Delivery notification delivery time is required!'),
              otherwise: Yup.number().nullable(true),
            }),
        }, ['body', 'deliveryMinutesAfter']),
        reminderNotification: Yup.object().shape({
          body: Yup.string()
            .when('reminderHoursBefore', {
              is: val => !!val,
              then: Yup.string().required('Reminder notification body is required!'),
              otherwise: Yup.string().nullable(true),
            }),
          reminderHoursBefore: Yup.number()
            .when('body', {
              is: val => !!val,
              then: Yup.number().required('Reminder notification delivery time is required!'),
              otherwise: Yup.number().nullable(true),
            }),
        }, ['body', 'reminderHoursBefore']),
        // imageName: Yup.string().required('Message image is required!'),
      })}
      onSubmit={(values) => {
        onSubmit({
          ...pick(values, [
            'messageDeliveryTypeId',
            'messageTypeId',
            'dynamicMessageTypeId',
            'reoccurringMessageTypeId',
            'title',
            'body',
            'arisMajor',
            'arisMinor',
            'onlineCode',
            'url',
            'disclaimer',
            'reoccurringStartDay',
            'dynamicConfirmation',
            'dynamicSmileyCodeWord',
            'templateDeliveryBody',
            'templateDeliveryMinutesAfter',
            'templateReminderBody',
            'templateReminderHoursBefore',
            'templateDuration',
            'templateAllowDuplicateDays',
            'locationIds',
          ]),
          deliveryNotification: values.deliveryNotification.deliveryMinutesAfter ?
                                  values.deliveryNotification : null,
          reminderNotification: values.reminderNotification.reminderHoursBefore ?
                                  values.reminderNotification : null,
          startDate: getIsoFormattedString(
            values.startMoment,
            values.startTime,
            values.startMeridiem,
          ),
          endDate: getIsoFormattedString(
            values.endMoment,
            values.endTime,
            values.endMeridiem,
          ),
        });
      }}
      render={({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isValid,
        setFieldValue,
        setFieldTouched,
        setStatus,
        status,
      }) => (
        <Segment clearing>
          <Header size="medium">Message</Header>

          <Form onSubmit={handleSubmit} error={!!errors} loading={isSubmitting}>
            <Form.Dropdown
              name="messageDeliveryTypeId"
              label="Message Delivery Type"
              placeholder="Message Delivery Type"
              options={messageDeliveryTypeOptions}
              error={touched.messageDeliveryTypeId && !!errors.messageDeliveryTypeId}
              value={values.messageDeliveryTypeId}
              onBlur={() => setFieldTouched('messageDeliveryTypeId', true)}
              onChange={(e, { value }) => setFieldValue('messageDeliveryTypeId', value)}
              selection
              required
            />

            {
              errors && errors.messageDeliveryTypeId && touched.messageDeliveryTypeId &&
              <Message negative content={errors.messageDeliveryTypeId} size="mini" />
            }

            <Form.Input
              name="title"
              label="Title"
              placeholder="Title"
              value={values.title}
              error={touched.title && !!errors.title}
              onBlur={handleBlur}
              onChange={handleChange}
              required
            />

            {
              errors && errors.title && touched.title &&
              <Message negative content={errors.title} size="mini" />
            }

            <Form.TextArea
              name="body"
              label="Content"
              placeholder="Message body"
              value={values.body}
              error={touched.body && !!errors.body}
              onBlur={handleBlur}
              onChange={handleChange}
              required
            />

            {
              errors && errors.body && touched.body &&
              <Message negative content={errors.body} size="mini" />
            }

            {/* eslint-disable jsx-a11y/label-has-for */}
            <Form.Group unstackable>
              <Form.Field required>
                <label>Start Date</label>

                <SingleDatePicker
                  id="start-date-picker"
                  date={values.startMoment}
                  numberOfMonths={1}
                  onDateChange={date => setFieldValue('startMoment', date)}
                  focused={status && status.startDateFocused}
                  onFocusChange={({ focused }) =>
                    setStatus({ ...status, startDateFocused: focused })
                  }
                />
              </Form.Field>

              <Form.Field required>
                <label>Start Time</label>
                <TimePicker
                  id="start-time-picker"
                  onFocusChange={focused => setStatus({ ...status, startTimeFocused: focused })}
                  focused={status && status.startTimeFocused}
                  meridiem={values.startMeridiem}
                  time={values.startTime}
                  onTimeChange={({ hour, minute, meridiem }) => {
                    setFieldValue('startTime', `${hour}:${minute.includes('-') ? 60 - minute.split('-')[1] : minute}`);
                    setFieldValue('startMeridiem', meridiem);
                  }}
                />
              </Form.Field>
            </Form.Group>

            {errors &&
              errors.startMoment &&
              touched.startMoment && (
                <Message negative content={errors.startMoment} size="mini" />
              )}

            {/* eslint-disable jsx-a11y/label-has-for */}
            <Form.Group unstackable>
              <Form.Field required>
                <label>End Date</label>

                <SingleDatePicker
                  id="end-date-picker"
                  date={values.endMoment}
                  numberOfMonths={1}
                  onDateChange={date => setFieldValue('endMoment', date)}
                  focused={status && status.endDateFocused}
                  onFocusChange={({ focused }) => setStatus({ ...status, endDateFocused: focused })}
                />
              </Form.Field>

              <Form.Field required>
                <label>End Time</label>
                <TimePicker
                  id="end-time-picker"
                  onFocusChange={focused => setStatus({ ...status, endTimeFocused: focused })}
                  focused={status && status.endTimeFocused}
                  meridiem={values.endMeridiem}
                  time={values.endTime}
                  onTimeChange={({ hour, minute, meridiem }) => {
                    setFieldValue('endTime', `${hour}:${minute.includes('-') ? 60 - minute.split('-')[1] : minute}`);
                    setFieldValue('endMeridiem', meridiem);
                  }}
                />
              </Form.Field>
            </Form.Group>

            {
              errors &&
              errors.endMoment &&
              touched.endMoment && (
                <Message negative content={errors.endMoment} size="mini" />
              )
            }

            <Accordion fluid>
              <Accordion.Title
                active={values.activeIndex.includes('messageType')}
                index={0}
                onClick={() => {
                  const newActive = values.activeIndex;
                  if (values.activeIndex.includes('messageType')) {
                    newActive.splice(newActive.indexOf('messageType'), 1);
                    setFieldValue('activeIndex', newActive);
                  } else {
                    setFieldValue('activeIndex', [...newActive, 'messageType']);
                  }
                }}
              >
                <Icon name="dropdown" />
                Message Type
              </Accordion.Title>

              <Accordion.Content active={values.activeIndex.includes('messageType')}>
                <Form.Dropdown
                  name="messageTypeId"
                  label="Message Type"
                  placeholder="Message Type"
                  options={messageTypeOptions}
                  error={touched.messageTypeId && !!errors.messageTypeId}
                  value={values.messageTypeId}
                  onBlur={() => setFieldTouched('messageTypeId', true)}
                  onChange={(e, { value }) => setFieldValue('messageTypeId', value)}
                  selection
                  required
                />

                {
                  errors && errors.messageDeliveryTypeId && touched.messageDeliveryTypeId &&
                  <Message negative content={errors.messageDeliveryTypeId} size="mini" />
                }

                {
                  values.messageTypeId && [3, 4].includes(values.messageTypeId) && (
                    <React.Fragment>
                      <Form.Input
                        name="url"
                        label="Url"
                        placeholder="Url"
                        value={values.url}
                        error={touched.url && !!errors.url}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                      />
                      {
                        errors && errors.url && touched.url &&
                        <Message negative content={errors.url} size="mini" />
                      }
                    </React.Fragment>
                  )
                }

                {
                  values.messageTypeId && values.messageTypeId === 5 && (
                    <React.Fragment>
                      <Form.Group inline>
                        <Form.Input
                          name="arisMajor"
                          label="ARIS"
                          placeholder="ARIS Major"
                          value={values.arisMajor}
                          error={touched.arisMajor && !!errors.arisMajor}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          required
                        />
                        <Form.Input
                          name="arisMinor"
                          placeholder="ARIS Minor"
                          value={values.arisMinor}
                          error={touched.arisMinor && !!errors.arisMinor}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          required
                        />
                      </Form.Group>

                      {
                        errors && (errors.arisMajor || errors.arisMinor)
                        && (touched.arisMajor && touched.arisMinor) &&
                        <Message negative content={errors.arisMajor || errors.arisMinor} size="mini" />
                      }

                      <Form.Input
                        name="onlineCode"
                        placeholder="Online Code"
                        value={values.onlineCode}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />

                      <Form.TextArea
                        name="disclaimer"
                        label="Disclaimer"
                        placeholder="Disclaimer"
                        value={values.disclaimer}
                        error={touched.disclaimer && !!errors.disclaimer}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />

                      {
                        errors && errors.disclaimer && touched.disclaimer &&
                        <Message negative content={errors.disclaimer} size="mini" />
                      }
                    </React.Fragment>
                  )
                }
              </Accordion.Content>

              <Accordion.Title
                active={values.activeIndex.includes('locations')}
                index={0}
                onClick={() => {
                  const newActive = values.activeIndex;
                  if (values.activeIndex.includes('locations')) {
                    newActive.splice(newActive.indexOf('locations'), 1);
                    setFieldValue('activeIndex', newActive);
                  } else {
                    setFieldValue('activeIndex', [...newActive, 'locations']);
                  }
                }}
              >
                <Icon name="dropdown" />
                Locations
              </Accordion.Title>
              <Accordion.Content active={values.activeIndex.includes('locations')}>
                <Multiselect
                  options={locationOptions}
                  value={values.locationIds}
                  handleChange={(e, { value }) => setFieldValue('locationIds', value)}
                />
              </Accordion.Content>

              {
                values.messageDeliveryTypeId && values.messageDeliveryTypeId !== 1 && (
                  <React.Fragment>
                    <Accordion.Title
                      active={values.activeIndex.includes('template')}
                      index={1}
                      onClick={() => {
                        const newActive = values.activeIndex;
                        if (values.activeIndex.includes('template')) {
                          newActive.splice(newActive.indexOf('template'), 1);
                          setFieldValue('activeIndex', newActive);
                        } else {
                          setFieldValue('activeIndex', [...newActive, 'template']);
                        }
                      }}
                    >
                      <Icon name="dropdown" />
                      {values.messageDeliveryTypeId === 2 ? 'Dynamic' : 'Reoccuring'} Message
                    </Accordion.Title>
                    <Accordion.Content active={values.activeIndex.includes('template')}>
                      {
                        values.messageDeliveryTypeId === 2 &&
                        <React.Fragment>
                          <Form.Dropdown
                            name="dynamicMessageTypeId"
                            label="Dynamic Message Type"
                            placeholder="Dynamic Message Type"
                            options={dynamicTypeOptions}
                            error={touched.dynamicMessageTypeId && !!errors.dynamicMessageTypeId}
                            value={values.dynamicMessageTypeId}
                            onBlur={() => setFieldTouched('dynamicMessageTypeId', true)}
                            onChange={(e, { value }) => setFieldValue('dynamicMessageTypeId', value)}
                            selection
                            required
                          />

                          {
                            values.dynamicMessageTypeId === 7 &&
                            <Form.Input
                              name="dynamicSmileyCodeWord"
                              label="Smiley Code Word"
                              placeholder="Smiley Code Word"
                              value={values.dynamicSmileyCodeWord}
                              error={
                                touched.dynamicSmileyCodeWord && !!errors.dynamicSmileyCodeWord
                              }
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          }

                          <Form.TextArea
                            name="dynamicConfirmation"
                            label="Confirmation Alert"
                            placeholder="Confirmation Alert"
                            value={values.dynamicConfirmation}
                            error={
                              touched.dynamicConfirmation && !!errors.dynamicConfirmation
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </React.Fragment>
                      }

                      {
                        values.messageDeliveryTypeId === 3 &&
                        <React.Fragment>
                          <Form.Dropdown
                            name="reoccurringMessageTypeId"
                            label="Reoccurring Message Type"
                            placeholder="Reoccurring Message Type"
                            options={reoccurringTypeOptions}
                            error={
                              touched.reoccurringMessageTypeId &&
                              !!errors.reoccurringMessageTypeId
                            }
                            value={values.reoccurringMessageTypeId}
                            onBlur={() => {
                              setFieldTouched('reoccurringMessageTypeId', true);
                            }}
                            onChange={(e, { value }) => {
                              setFieldValue('reoccurringMessageTypeId', value);
                            }}
                            selection
                            required
                          />
                          <Form.Input
                            name="reoccurringStartDay"
                            label="Delivery days before event"
                            value={values.reoccurringStartDay}
                            error={
                              touched.reoccurringStartDay &&
                              !!errors.reoccurringStartDay
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </React.Fragment>
                      }

                      <Form.Input
                        name="templateDuration"
                        label="Duration (days)"
                        value={values.templateDuration}
                        error={
                          touched.templateDuration &&
                          !!errors.templateDuration
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />

                      <Form.Input
                        name="templateAllowDuplicateDays"
                        label="Allow duplicate every"
                        placeholder=""
                        value={values.templateAllowDuplicateDays}
                        error={
                          touched.templateAllowDuplicateDays &&
                          !!errors.templateAllowDuplicateDays
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Accordion.Content>
                  </React.Fragment>
                )
              }

              <Accordion.Title
                active={values.activeIndex.includes('delivery')}
                index={1}
                onClick={() => {
                  const newActive = values.activeIndex;
                  if (values.activeIndex.includes('delivery')) {
                    newActive.splice(newActive.indexOf('delivery'), 1);
                    setFieldValue('activeIndex', newActive);
                  } else {
                    setFieldValue('activeIndex', [...newActive, 'delivery']);
                  }
                }}
              >
                <Icon name="dropdown" />
                  Delivery Notification
              </Accordion.Title>
              <Accordion.Content active={values.activeIndex.includes('delivery')}>
                <Form.TextArea
                  name="deliveryNotification.body"
                  label="Content"
                  placeholder="Delivery notification body"
                  value={values.deliveryNotification.body}
                  error={
                    touched.deliveryNotification &&
                    touched.deliveryNotification.body &&
                    !!errors.deliveryNotification &&
                    !!errors.deliveryNotification.body
                  }
                  onBlur={handleBlur}
                  onChange={handleChange}
                />

                <Form.Input
                  name="deliveryNotification.deliveryMinutesAfter"
                  label="Minutes to send delivery after start"
                  placeholder="2"
                  value={values.deliveryNotification.deliveryMinutesAfter}
                  error={
                    touched.deliveryNotification &&
                    touched.deliveryNotification.deliveryMinutesAfter &&
                    !!errors.deliveryNotification &&
                    !!errors.deliveryNotification.deliveryMinutesAfter
                  }
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Accordion.Content>

              <Accordion.Title
                active={values.activeIndex.includes('reminder')}
                index={1}
                onClick={() => {
                  const newActive = values.activeIndex;
                  if (values.activeIndex.includes('reminder')) {
                    newActive.splice(newActive.indexOf('reminder'), 1);
                    setFieldValue('activeIndex', newActive);
                  } else {
                    setFieldValue('activeIndex', [...newActive, 'reminder']);
                  }
                }}
              >
                <Icon name="dropdown" />
                Reminder Notification
              </Accordion.Title>
              <Accordion.Content active={values.activeIndex.includes('reminder')}>
                <Form.TextArea
                  name="reminderNotification.body"
                  label="Content"
                  placeholder="Reminder notification body"
                  value={values.reminderNotification.body}
                  error={
                    touched.reminderNotification &&
                    touched.reminderNotification.body &&
                    !!errors.reminderNotification &&
                    !!errors.reminderNotification.body
                  }
                  onBlur={handleBlur}
                  onChange={handleChange}
                />

                <Form.Input
                  name="reminderNotification.reminderHoursBefore"
                  label="Hours to send reminder before expiration"
                  placeholder="60"
                  value={values.reminderNotification.reminderHoursBefore}
                  error={
                    touched.reminderNotification &&
                    touched.reminderNotification.reminderHoursBefore &&
                    !!errors.reminderNotification &&
                    !!errors.reminderNotification.reminderHoursBefore
                  }
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Accordion.Content>
            </Accordion>

            {
              errors && errors.imageName &&
              <Message negative content={errors.imageName} size="mini" />
            }

            <Divider />

            <Button color="teal" disabled={isSubmitting || !isValid} floated="right">
              Save
            </Button>
          </Form>
        </Segment>
      )}
    />
  );
};

MessageEditForm.propTypes = {
  messageDeliveryTypeId: PropTypes.number,
  messageDeliveryTypes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })),
  title: PropTypes.string,
  body: PropTypes.string,
  messageTypeId: PropTypes.number,
  messageTypes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })),
  dynamicMessageTypeId: PropTypes.number,
  dynamicMessageTypes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })),
  reoccurringMessageTypeId: PropTypes.number,
  reoccurringMessageTypes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })),
  arisMajor: PropTypes.string,
  arisMinor: PropTypes.string,
  onlineCode: PropTypes.string,
  url: PropTypes.string,
  disclaimer: PropTypes.string,
  imageName: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  reoccurringStartDay: PropTypes.number,
  dynamicConfirmation: PropTypes.string,
  dynamicSmileyCodeWord: PropTypes.string,
  templateDeliveryBody: PropTypes.string,
  templateDeliveryMinutesAfter: PropTypes.number,
  templateReminderBody: PropTypes.string,
  templateReminderHoursBefore: PropTypes.number,
  templateDuration: PropTypes.number,
  templateAllowDuplicateDays: PropTypes.number,
  locationIds: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
    text: PropTypes.text,
  })),
  locations: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })),
  deliveryNotification: PropTypes.shape({
    messagePushNotificationTypeId: PropTypes.number.isRequired,
    body: PropTypes.string,
    deliveryMinutesAfter: PropTypes.number,
    reminderHoursBefore: PropTypes.number,
  }),
  reminderNotification: PropTypes.shape({
    messagePushNotificationTypeId: PropTypes.number.isRequired,
    body: PropTypes.string,
    deliveryMinutesAfter: PropTypes.number,
    reminderHoursBefore: PropTypes.number,
  }),
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

MessageEditForm.defaultProps = {
  messageDeliveryTypeId: null,
  messageDeliveryTypes: [],
  title: null,
  body: null,
  arisMajor: null,
  arisMinor: null,
  onlineCode: null,
  url: null,
  disclaimer: null,
  imageName: null,
  messageTypes: [],
  messageTypeId: null,
  dynamicMessageTypes: [],
  dynamicMessageTypeId: null,
  reoccurringMessageTypes: [],
  reoccurringMessageTypeId: null,
  startDate: null,
  endDate: null,
  reoccurringStartDay: null,
  dynamicConfirmation: null,
  dynamicSmileyCodeWord: null,
  templateDeliveryBody: null,
  templateDeliveryMinutesAfter: null,
  templateReminderBody: null,
  templateReminderHoursBefore: null,
  templateDuration: null,
  templateAllowDuplicateDays: null,
  locationIds: [],
  locations: [],
  deliveryNotification: {
    messagePushNotificationTypeId: 1,
    body: '',
    deliveryMinutesAfter: null,
  },
  reminderNotification: {
    messagePushNotificationTypeId: 2,
    body: '',
    reminderHoursBefore: null,
  },
};

export default MessageEditForm;
