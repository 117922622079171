import {
  HOURS_FETCHING_REQUEST,
  HOURS_FETCHING_SUCCESS,
  HOURS_FETCHING_ERROR,
  HOURS_UPDATING_REQUEST,
  HOURS_UPDATING_SUCCESS,
  HOURS_UPDATING_ERROR,
} from './constants';

const initialState = {
  requesting: false,
  successful: false,
  selectedSchedule: {},
  errors: [],
};

export default (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case HOURS_FETCHING_REQUEST:
      return {
        requesting: true,
        successful: false,
        selectedSchedule: {},
        errors: [],
      };
    case HOURS_FETCHING_SUCCESS:
      return {
        requesting: false,
        successful: true,
        selectedSchedule: action.payload.hours,
        errors: [],
      };
    case HOURS_FETCHING_ERROR:
      return {
        requesting: false,
        successful: false,
        selectedSchedule: {},
        errors: state.errors.concat([{
          body: action.error.toString(),
          time: new Date(),
        }]),
      };
    case HOURS_UPDATING_REQUEST:
      return {
        ...state,
        requesting: true,
        successful: false,
        errors: [],
      };
    case HOURS_UPDATING_SUCCESS:
      return {
        ...state,
        requesting: false,
        successful: true,
        errors: [],
      };
    case HOURS_UPDATING_ERROR:
      return {
        ...state,
        requesting: false,
        successful: false,
        errors: state.errors.concat([{
          body: action.error.toString(),
          time: new Date(),
        }]),
      };
    default:
      return state;
  }
};
