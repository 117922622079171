import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { history } from '@/app/store';

import api from '@/common/api';
import OperatingHoursEditForm from '@/features/hours-of-operation/OperatingHoursEditForm';

const UPDATE_ERROR = 'Error updating hours of operation!';
const UPDATE_SUCCESS = 'Successfully updated hours of operation!';

class OperatingHoursCreatePage extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string,
      }).isRequired,
    }).isRequired,
  };

  state = {
    loading: false,
  };

  handleSubmit = (hours) => {
    const { id } = this.props.match.params;

    this.setState({ loading: true }, async () => {
      try {
        const { data } = await api.post(`/api/location/${id}/hours`, hours);
        toast.success(UPDATE_SUCCESS);
        history.push(`/locations/${id}/hours/${data.id}`);
      } catch (e) {
        toast.error(UPDATE_ERROR);
      }

      this.setState({ loading: false });
    });
  };

  render() {
    return (
      <OperatingHoursEditForm
        isSubmitting={this.state.loading}
        onSubmit={this.handleSubmit}
      />
    );
  }
}


export default withRouter(OperatingHoursCreatePage);
