import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router-dom';

import SurveysPage from './pages/SurveysPage';
import SurveyEditPage from './pages/SurveyEditPage';

const Surveys = ({ match }) => (
  <Switch>
    <Route path={`${match.url}`} exact component={SurveysPage} />
    <Route path={`${match.url}/create`} exact component={SurveyEditPage} />
    <Route path={`${match.url}/:id`} exact component={SurveyEditPage} />
  </Switch>
);

Surveys.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(Surveys);
