import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react/dist/es';

const Multiselect = ({
  placeholder,
  // keyword,
  options,
  value,
  handleChange,
  handleSearchChange,
}) => (
  <Dropdown
    fluid
    multiple
    search
    selection
    value={value}
    placeholder={placeholder}
    options={options}
    onChange={handleChange}
    onSearchChange={handleSearchChange}
  />
);

Multiselect.propTypes = {
  // keyword: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.string),
  options: PropTypes.arrayOf(PropTypes.shape).isRequired,
  placeholder: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  handleSearchChange: PropTypes.func.isRequired,
};

Multiselect.defaultProps = {
  // keyword: '',
  value: [],
  placeholder: 'Search',
};

export default Multiselect;
