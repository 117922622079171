import { fork, call, put, take } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '@/common/api';
import {
  HOURS_FETCHING_REQUEST,
  HOURS_FETCHING_ERROR,
  HOURS_UPDATING_REQUEST,
  HOURS_UPDATING_ERROR,
  HOURS_UPDATING_SUCCESS,
  MSG_FETCH_ERROR,
  MSG_UPDATE_SUCCESS,
  MSG_UPDATE_ERROR,
} from './constants';

import { fetchHoursSuccess } from './actions';

function getHours(locationId, id) {
  return api.get(`/api/location/${locationId}/hours/${id}`);
}

function* hoursRequestWatcher() {
  while (true) {
    const { payload } = yield take(HOURS_FETCHING_REQUEST);

    try {
      const { data: hours } = yield call(getHours, payload.locationId, payload.id);

      yield put(fetchHoursSuccess(hours));
    } catch (error) {
      yield put({ type: HOURS_FETCHING_ERROR, error });
      yield call(toast.error, MSG_FETCH_ERROR);
    }
  }
}

function updateHours(locationId, id, hours) {
  return api.put(`/api/location/${locationId}/hours/${id}`, hours);
}

function* hoursUpdateWatcher() {
  while (true) {
    const { payload } = yield take(HOURS_UPDATING_REQUEST);

    try {
      const { locationId, id, hours } = payload;
      yield call(updateHours, locationId, id, hours);
      yield put({ type: HOURS_UPDATING_SUCCESS });
      yield call(toast.success, MSG_UPDATE_SUCCESS);
    } catch (error) {
      yield put({ type: HOURS_UPDATING_ERROR, error });
      yield call(toast.error, MSG_UPDATE_ERROR);
    }
  }
}

export default function* root() {
  yield fork(hoursRequestWatcher);
  yield fork(hoursUpdateWatcher);
}
