import React from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react/dist/es';

import FileUpload from '@/common/components/FileUpload';
import ImagePreview from '@/common/components/ImagePreview';

const CONTENT_TYPES = ['image/jpeg', 'image/png'];

const ImageUpload = ({ loading, onUpload, image }) => (
  <Segment loading={loading}>
    <ImagePreview image={image} />
    <FileUpload
      guidance="Click to upload image"
      contentTypes={CONTENT_TYPES}
      onUpload={onUpload}
    />
  </Segment>
);

ImageUpload.propTypes = {
  loading: PropTypes.bool.isRequired,
  onUpload: PropTypes.func.isRequired,
  image: PropTypes.string,
};

ImageUpload.defaultProps = {
  image: '',
};

export default ImageUpload;
