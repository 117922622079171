export const HOURS_FETCHING_REQUEST = 'HOURS_FETCHING_REQUEST';
export const HOURS_FETCHING_SUCCESS = 'HOURS_FETCHING_SUCCESS';
export const HOURS_FETCHING_ERROR = 'HOURS_FETCHING_ERROR';

export const HOURS_UPDATING_REQUEST = 'HOURS_UPDATING_REQUEST';
export const HOURS_UPDATING_SUCCESS = 'HOURS_UPDATING_SUCCESS';
export const HOURS_UPDATING_ERROR = 'HOURS_UPDATING_ERROR';

export const MSG_UPDATE_SUCCESS = 'Successfully updated schedule!';
export const MSG_UPDATE_ERROR = 'Error updating schedule!';
export const MSG_FETCH_ERROR = 'Error retrieving schedule!';
