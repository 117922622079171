import gql from 'graphql-tag';

export const CREATE_SCHEDULE_DETAIL = gql`
  mutation createScheduleDetail($input: CreateOperatingHoursDetailInput!) {
    createScheduleDetail(input: $input) {
      detail {
        id
        open
        openFormatted
        close
        closeFormatted
        day
        dayId
      }
    }
  }
`;

export const DELETE_SCHEDULE_DETAIL = gql`
  mutation deleteScheduleDetail($input: DeleteOperatingHoursDetailInput!) {
    deleteScheduleDetail(input: $input) {
      id
    }
  }
`;
