import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Icon, Input, Menu } from 'semantic-ui-react/dist/es';
import { debounce } from 'lodash-es';

import LocationsList from '@/features/locations/LocationsList';

const LocationsMenu = ({ onSearchChange }) => (
  <Menu stackable borderless secondary>
    <Menu.Item>
      <Button as={Link} to="/locations/create" icon primary size="mini" labelPosition="left">
        <Icon name="add" />
        Create Location
      </Button>
    </Menu.Item>
    <Menu.Item position="right">
      <Input
        className="icon"
        icon="search"
        placeholder="Search..."
        onChange={onSearchChange}
      />
    </Menu.Item>
  </Menu>
);

LocationsMenu.propTypes = {
  onSearchChange: PropTypes.func.isRequired,
};

class LocationsPage extends React.Component {
  state = {
    keyword: '',
  }

  handleSearchChange = debounce((e, data) => {
    this.setState({
      keyword: data.value,
    });
  }, 500);

  render() {
    return (
      <React.Fragment>
        <LocationsMenu onSearchChange={this.handleSearchChange} />
        <LocationsList keyword={this.state.keyword} />
      </React.Fragment>
    );
  }
}

export default LocationsPage;
