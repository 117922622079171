import { CONTEXT_MENU_HIDE, CONTEXT_MENU_SHOW } from './constants';

export const showContextMenu = (x, y, type, menuArgs) => ({
  type: CONTEXT_MENU_SHOW,
  payload: {
    location: {
      x,
      y,
    },
    type,
    menuArgs,
  },
});

export const hideContextMenu = () => ({
  type: CONTEXT_MENU_HIDE,
});
