import React from 'react';
import { toast } from 'react-toastify';

import api from '@/common/api';
import FranchiseEditForm from '@/features/franchise/FranchiseEditForm';

const UPDATE_SUCCESS = 'Successfully updated franchise!';
const UPDATE_ERROR = 'Error creating franchise!';
const FETCH_ERROR = 'Error retrieving franchise information!';

class FranchiseEditFormContainer extends React.Component {
  state = {
    loading: true,
    franchise: {},
  };

  async componentDidMount() {
    try {
      const { data: franchise } = await api.get('/api/franchise');

      this.setState({
        loading: false,
        franchise,
      });
    } catch (e) {
      toast.error(FETCH_ERROR);
    }
  }

  handleSubmit = (franchise) => {
    this.setState({ loading: true }, async () => {
      try {
        await api.put('/api/franchise', franchise);
        toast.success(UPDATE_SUCCESS);
      } catch (e) {
        toast.error(UPDATE_ERROR);
      }

      this.setState({ loading: false });
    });
  };

  render() {
    return (
      <FranchiseEditForm
        {...this.state.franchise}
        onSubmit={this.handleSubmit}
        isSubmitting={this.state.loading}
      />
    );
  }
}

export default FranchiseEditFormContainer;
