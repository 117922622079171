import { createReducer } from '@/common/utils/reducer-utils';

import {
  CONTEXT_MENU_SHOW,
  CONTEXT_MENU_HIDE,
} from './constants';

const initialState = {
  show: false,
  location: {
    x: null,
    y: null,
  },
  type: null,
  menuArgs: undefined,
};

const showContextMenu = (state, payload) => ({
  ...state,
  show: true,
  ...payload,
});

const hideContextMenu = () => ({
  ...initialState,
});

export default createReducer(initialState, {
  [CONTEXT_MENU_SHOW]: showContextMenu,
  [CONTEXT_MENU_HIDE]: hideContextMenu,
});
