import {
  HOURS_FETCHING_REQUEST,
  HOURS_FETCHING_SUCCESS,
  HOURS_UPDATING_REQUEST,
} from './constants';

export const fetchHoursRequest = (locationId, id) => ({
  type: HOURS_FETCHING_REQUEST,
  payload: {
    locationId,
    id,
  },
});

export const fetchHoursSuccess = hours => ({
  type: HOURS_FETCHING_SUCCESS,
  payload: {
    hours,
  },
});

export const updateHoursRequest = (locationId, id, hours) => ({
  type: HOURS_UPDATING_REQUEST,
  payload: {
    locationId,
    id,
    hours,
  },
});
