import React from 'react';
import { Route, withRouter } from 'react-router-dom';

import LocationCreateFormContainer from '@/features/locations/LocationEditForm/LocationCreateFormContainer';

const LocationCreatePage = () => (
  <Route component={LocationCreateFormContainer} />
);

export default withRouter(LocationCreatePage);
