import React from 'react';
import PropTypes from 'prop-types';
import { Table, Loader, Pagination } from 'semantic-ui-react/dist/es';
import { Link } from 'react-router-dom';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';

import EnabledIndicator from '@/common/components/EnabledIndicator';
import { getFormattedDateString } from '@/common/utils/date-utils';

const PAGE_SIZE = 10;

const SURVEYS_QUERY = gql`
  query SurveysQuery($search: SearchInputType) {
    surveyGroupsPagination(search: $search) {
      page
      totalPages
      results {
        id
        name
        description
        startDate
        endDate
        enabled
      }
    }
  }
`;

/* eslint-disable object-curly-newline */
const SurveysList = ({ keyword }) => (
  <Query
    query={SURVEYS_QUERY}
    variables={{
      search: {
        pageSize: PAGE_SIZE,
        page: 1,
        sorts: 'name',
        filters: `name@=${keyword}`,
      },
    }}
  >
    {({ data: { surveyGroupsPagination }, loading, variables, refetch }) => {
      if (loading) {
        return <Loader active />;
      }

      return (
        <React.Fragment>
          <Table celled sortable striped selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Description</Table.HeaderCell>
                <Table.HeaderCell>Start Date</Table.HeaderCell>
                <Table.HeaderCell>End Date</Table.HeaderCell>
                <Table.HeaderCell>Enabled</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {
                surveyGroupsPagination.results && surveyGroupsPagination.results.map(({
                  id, name, description, startDate, endDate, enabled,
                }) => (
                  <Table.Row key={id}>
                    <Table.Cell>
                      <Link to={`/surveys/${id}`}>{name}</Link>
                    </Table.Cell>
                    <Table.Cell>{description}</Table.Cell>
                    <Table.Cell>{getFormattedDateString(startDate)}</Table.Cell>
                    <Table.Cell>{getFormattedDateString(endDate)}</Table.Cell>
                    <Table.Cell collapsing textAlign="center">
                      <EnabledIndicator enabled={enabled} />
                    </Table.Cell>
                  </Table.Row>
                ))
              }
            </Table.Body>
          </Table>
          {surveyGroupsPagination.totalPages > 1 && (
            <Pagination
              activePage={surveyGroupsPagination.page}
              totalPages={surveyGroupsPagination.totalPages}
              onPageChange={
                (e, { activePage }) => {
                  if (activePage !== surveyGroupsPagination.page) {
                    refetch({
                      search: {
                        pageSize: PAGE_SIZE,
                        page: activePage,
                        sorts: 'name',
                        filters: `name@=${keyword}`,
                      },
                    });
                  }
                }
              }
              firstItem={
                variables.search.page === 1 ?
                  null :
                  undefined
              }
              prevItem={
                variables.search.page === 1 ?
                  null :
                  undefined
              }
              lastItem={
                variables.search.page === surveyGroupsPagination.totalPages ?
                  null :
                  undefined
              }
              nextItem={
                variables.search.page === surveyGroupsPagination.totalPages ?
                  null :
                  undefined
              }
            />
          )}
        </React.Fragment>
      );
    }}
  </Query>
);

SurveysList.propTypes = {
  keyword: PropTypes.string,
};

SurveysList.defaultProps = {
  keyword: '',
};

export default SurveysList;
