import React from 'react';
import PropTypes from 'prop-types';
import { Button, Divider, Form, Header, Segment } from 'semantic-ui-react/dist/es';
import { Formik } from 'formik';

import Multiselect from '@/common/components/Multiselect';

const transformValues = (data) => {
  if (!(data && data.length)) {
    return [];
  }

  return data.map(l => ({
    key: l.id,
    value: l.id,
    text: l.name,
  }));
};

const LocationGroupMemberForm = ({
  locations,
  selectedLocations,
  handleLocationChange,
  onSubmit,
  isSubmitting,
}) => {
  const options = transformValues(locations);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        selectedLocations: selectedLocations || [],
      }}
      onSubmit={values => onSubmit(values)}
      render={({
        values, errors, handleSubmit,
      }) => (
        <Segment clearing>
          <Header size="medium">Membership</Header>
          <Form onSubmit={handleSubmit} error={!!errors} loading={isSubmitting}>
            <Multiselect
              options={options}
              value={values.selectedLocations}
              handleChange={handleLocationChange}
            />

            <Divider />

            <Button color="teal" disabled={isSubmitting} floated="right">
              Save
            </Button>
          </Form>
        </Segment>
      )}
    />
  );
};

LocationGroupMemberForm.propTypes = {
  selectedLocations: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
    text: PropTypes.text,
  })),
  locations: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })),
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleLocationChange: PropTypes.func.isRequired,
};

LocationGroupMemberForm.defaultProps = {
  locations: [],
  selectedLocations: [],
};

export default LocationGroupMemberForm;
