/* eslint-disable no-unused-vars */
import React from 'react';
import { Grid, Image, Segment } from 'semantic-ui-react/dist/es';
import styled from 'styled-components';
import userManager from '@/common/utils/user-manager';
import logo from '@/common/assets/brand-logo.png';
import background from '@/common/assets/temp-background.jpg';
import LoginForm from './LoginForm';

const BackgroundImage = styled.img`
  height: 100%;
  width: 100%;
  background-size: cover;
  position: absolute;
  filter: blur(5px);
`;

class Login extends React.Component {
  state = {
    loading: false,
  }

  handleLogin = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    }, () => {
      userManager.signinRedirect();
    });
  };

  render() {
    return (
      <React.Fragment>
        <BackgroundImage src={background} />
        <Grid textAlign="center" style={{ height: '100vh' }} verticalAlign="middle">
          <Grid.Column style={{ maxWidth: 450 }}>
            <Segment>
              <Image src={logo} />

              <LoginForm onSubmit={this.handleLogin} loading={this.state.loading} />
            </Segment>
          </Grid.Column>
        </Grid>
      </React.Fragment>
    );
  }
}

export default Login;
