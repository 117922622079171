import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Grid } from 'semantic-ui-react/dist/es';

import api from '@/common/api';
import { history } from '@/app/store';
import StatusToggle from '@/common/components/StatusToggle';
import SurveyEditForm from '@/features/surveys/SurveyEditForm';

const UPDATE_SUCCESS = 'Successfully updated survey!';
const UPDATE_ERROR = 'Error updating survey!';

class SurveyEditPage extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string,
      }).isRequired,
    }).isRequired,
  };

  state = {
    loading: true,
    survey: {},
  };

  async componentDidMount() {
    const { id } = this.props.match.params;

    try {
      let survey = {};
      if (id) {
        ({ data: survey } = await api.get(`/api/surveygroup/${id}`));
      }

      this.setState({
        loading: false,
        survey,
      });
    } catch (e) {
      toast.error(UPDATE_ERROR);
    }
  }

  handleSubmit = (survey) => {
    const { id } = this.props.match.params;

    this.setState({ loading: true }, async () => {
      try {
        const promise = id ?
          api.put(`/api/surveygroup/${id}`, survey)
          : api.post('/api/surveygroup', survey);

        const response = await promise;
        toast.success(UPDATE_SUCCESS);
        if (!id) {
          history.push(`/surveys/${response.data.id}`);
        }
      } catch (e) {
        toast.error(UPDATE_ERROR);
      }

      this.setState({ loading: false });
    });
  };

  handleStatusToggle = () => {
    const { id } = this.props.match.params;
    const url = `/api/surveygroup/${id}/status`;

    this.setState({ loading: true }, async () => {
      const enabled = !this.state.survey.enabled;
      const promise = enabled ? api.put(url) : api.delete(url);
      try {
        await promise;
        toast.success(UPDATE_SUCCESS);
        this.setState(prevState => ({
          survey: {
            ...prevState.survey,
            enabled,
          },
        }));
      } catch (e) {
        toast.error(UPDATE_ERROR);
      }

      this.setState({ loading: false });
    });
  };

  render() {
    const { id } = this.props.match.params;

    return (
      <Grid stackable columns="equal">
        <Grid.Column>
          <SurveyEditForm
            {...this.state.survey}
            onSubmit={this.handleSubmit}
            isSubmitting={this.state.loading}
          />
        </Grid.Column>
        {
          id && (
          <Grid.Column width={4}>
            <StatusToggle
              enabled={this.state.survey.enabled}
              loading={this.state.loading}
              onClick={this.handleStatusToggle}
            />
          </Grid.Column>
        )}
      </Grid>
    );
  }
}

export default withRouter(SurveyEditPage);
