import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon, Menu } from 'semantic-ui-react/dist/es';
import { debounce } from 'lodash-es';

import SearchMenu from '@/common/components/SearchMenu';
import SurveysList from '@/features/surveys/SurveysList';

class SurveysPage extends React.Component {
  state = {
    keyword: '',
  }

  handleSearchChange = debounce((e, data) => {
    this.setState({
      keyword: data.value,
    });
  }, 500);

  render() {
    return (
      <React.Fragment>
        <SearchMenu onSearchChange={this.handleSearchChange}>
          <Menu.Item>
            <Button as={Link} to="/surveys/create" icon primary size="mini" labelPosition="left">
              <Icon name="add" />
              Create Survey
            </Button>
          </Menu.Item>
        </SearchMenu>
        <SurveysList keyword={this.state.keyword} />
      </React.Fragment>
    );
  }
}

export default SurveysPage;
