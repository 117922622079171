import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Grid } from 'semantic-ui-react/dist/es';

import api from '@/common/api';
import { history } from '@/app/store';
import ManagerEditForm from '@/features/managers/ManagerEditForm';

const UPDATE_SUCCESS = 'Successfully updated manager!';
const UPDATE_ERROR = 'Error updating manager!';

class ManagerEditPage extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string,
      }).isRequired,
    }).isRequired,
  };

  state = {
    loading: true,
    manager: {},
  };

  async componentDidMount() {
    const { id } = this.props.match.params;

    try {
      let manager = {};
      if (id) {
        ({ data: manager } = await api.get(`/api/management/${id}`));
      }

      this.setState({
        loading: false,
        manager,
      });
    } catch (e) {
      toast.error(UPDATE_ERROR);
    }
  }

  handleSubmit = (manager) => {
    const { id } = this.props.match.params;

    this.setState({ loading: true }, async () => {
      try {
        const promise = id ?
          api.put(`/api/management/${id}`, manager)
          : api.post('/api/management', manager);

        const response = await promise;
        toast.success(UPDATE_SUCCESS);
        if (!id) {
          history.push(`/managers/${response.data.id}`);
        }
      } catch (e) {
        toast.error(UPDATE_ERROR);
      }

      this.setState({ loading: false });
    });
  };

  render() {
    const { id } = this.props.match.params;

    return (
      <Grid stackable columns="equal">
        <Grid.Column>
          <ManagerEditForm
            {...this.state.manager}
            onSubmit={this.handleSubmit}
            isSubmitting={this.state.loading}
          />
        </Grid.Column>
        {
          id && (
          <Grid.Column width={4}>
            <span>TODO: Add Location picker</span>
          </Grid.Column>
        )}
      </Grid>
    );
  }
}

export default withRouter(ManagerEditPage);
