import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Icon } from 'semantic-ui-react/dist/es';

const LoginForm = ({ onSubmit, loading }) => (
  <Form size="large" onSubmit={onSubmit}>
    <Button color="blue" fluid size="large" loading={loading} disabled={loading}>
      <Icon name="windows" />
      Login with Office 365
    </Button>
  </Form>
);

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default LoginForm;
