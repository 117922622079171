import React from 'react';
import styled from 'styled-components';
import googleLogo from './powered_by_google_default.png';

const FooterDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 4px;
`;

const GoogleImage = styled.img`
  display: inline-block;
  width: 150px;
`;

const LocationPickerFooter = () => (
  <FooterDiv>
    <div>
      <GoogleImage
        src={googleLogo}
        alt="Powered by Google"
      />
    </div>
  </FooterDiv>
);

export default LocationPickerFooter;
