import gql from 'graphql-tag';

/* eslint-disable import/prefer-default-export */
export const OPERATING_HOURS_QUERY = gql`
  query OperatingHoursQuery($id: Int!, $hoursSearch: SearchInputType) {
    location(id: $id) {
      id
      hours(hoursSearch: $hoursSearch) {
        id
        startDate
        endDate
        name
      }
    }
  }
`;

export const OPERATING_HOURS_DETAIL_QUERY = gql`
  query OperatingHoursDetailQuery(
    $id: Int!,
    $hoursSearch: SearchInputType,
    $hoursDetailsSearch: SearchInputType
  ) {
    location(id: $id) {
      id
      hours(hoursSearch: $hoursSearch) {
        id
        startDate
        endDate
        name
        details(hoursDetailsSearch: $hoursDetailsSearch) {
          id
          open
          openFormatted
          close
          closeFormatted
          day
          dayId
        }
      }
    }
  }
`;
