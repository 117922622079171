import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { Button, Icon, Input, Menu } from 'semantic-ui-react/dist/es';
import { debounce } from 'lodash-es';

import OperatingHoursList from '@/features/hours-of-operation/OperatingHoursList';

const OperatingHoursMenu = ({ onSearchChange, match }) => (
  <Menu stackable borderless secondary>
    <Menu.Item>
      <Button as={Link} to={`${match.url}/create`} icon primary size="mini" labelPosition="left">
        <Icon name="add" />
        Create Schedule
      </Button>
    </Menu.Item>
    <Menu.Item position="right">
      <Input
        className="icon"
        icon="search"
        placeholder="Search..."
        onChange={onSearchChange}
      />
    </Menu.Item>
  </Menu>
);

OperatingHoursMenu.propTypes = {
  onSearchChange: PropTypes.func.isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
};

const RouterMenu = withRouter(OperatingHoursMenu);

class OperatingHoursPage extends React.Component {
  state = {
    keyword: '',
  }

  handleSearchChange = debounce((e, data) => {
    this.setState({
      keyword: data.value,
    });
  }, 500);

  render() {
    return (
      <React.Fragment>
        <RouterMenu onSearchChange={this.handleSearchChange} />
        <OperatingHoursList keyword={this.state.keyword} />
      </React.Fragment>
    );
  }
}

export default OperatingHoursPage;
