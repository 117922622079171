import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { pick } from 'lodash-es';
import Yup from 'yup';
import { Button, Divider, Form, Header, Message, Segment } from 'semantic-ui-react/dist/es';
import { SingleDatePicker } from 'react-dates';
import {
  getMomentFromDateString,
  getIsoFormattedString,
} from '@/common/utils/date-utils';

const OperatingHoursEditForm = ({
  name,
  startDate,
  endDate,
  onSubmit,
  isSubmitting,
}) => {
  const startMoment = getMomentFromDateString(startDate);
  const endMoment = getMomentFromDateString(endDate);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: name || '',
        startMoment,
        endMoment,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('Name is required!').max(100, 'Name must not exceed 100 characters!'),
        startMoment: Yup.date().required('Start date is required!'),
        endMoment: Yup.date().required('End date is required!'),
      })}
      onSubmit={(values) => {
        onSubmit({
          ...pick(values, [
            'name',
          ]),
          startDate: getIsoFormattedString(values.startMoment, '12:00', 'AM'),
          endDate: getIsoFormattedString(values.endMoment, '12:00', 'AM'),
        });
      }}
      render={({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isValid,
        setFieldValue,
        setStatus,
        status,
      }) => (
        <Segment clearing>
          <Header size="medium">Schedule</Header>

          <Form onSubmit={handleSubmit} error={!!errors} loading={isSubmitting}>
            <Form.Input
              name="name"
              label="Name"
              placeholder="Name"
              value={values.name}
              error={touched.name && !!errors.name}
              onBlur={handleBlur}
              onChange={handleChange}
              required
            />

            {
              errors && errors.name && touched.name &&
                <Message negative content={errors.name} size="mini" />
            }

            <Form.Group unstackable>
              <Form.Field required>
                {/* eslint-disable jsx-a11y/label-has-for */}
                <label>Start Date</label>

                <SingleDatePicker
                  id="start-date-picker"
                  date={values.startMoment}
                  numberOfMonths={1}
                  onDateChange={date => setFieldValue('startMoment', date)}
                  focused={status && status.startDateFocused}
                  onFocusChange={({ focused }) =>
                    setStatus({ ...status, startDateFocused: focused })
                  }
                />
              </Form.Field>
              <Form.Field required>
                <label>End Date</label>

                <SingleDatePicker
                  id="end-date-picker"
                  date={values.endMoment}
                  numberOfMonths={1}
                  onDateChange={date => setFieldValue('endMoment', date)}
                  focused={status && status.endDateFocused}
                  onFocusChange={({ focused }) => setStatus({ ...status, endDateFocused: focused })}
                />
              </Form.Field>
            </Form.Group>


            {errors &&
              errors.startMoment &&
              touched.startMoment && (
                <Message negative content={errors.startMoment} size="mini" />
              )}

            {errors &&
              errors.endMoment &&
              touched.endMoment && (
                <Message negative content={errors.endMoment} size="mini" />
              )}

            <Divider />

            <Button color="teal" disabled={isSubmitting || !isValid} floated="right">
              Save
            </Button>
          </Form>
        </Segment>
      )}
    />
  );
};

OperatingHoursEditForm.propTypes = {
  name: PropTypes.string,
  // messageTypes: PropTypes.arrayOf(PropTypes.shape({
  //   id: PropTypes.number.isRequired,
  //   name: PropTypes.string.isRequired,
  // })),
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

OperatingHoursEditForm.defaultProps = {
  name: '',
  startDate: null,
  endDate: null,
};

export default OperatingHoursEditForm;
