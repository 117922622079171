import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { formatBreadcrumb } from '@/common/components/NavBreadcrumb';
import { selectedScheduleName } from '@/features/hours-of-operation/selectors';

export const OperatingHoursBreadcrumb = ({ name }) => <span>{formatBreadcrumb(name)}</span>;

OperatingHoursBreadcrumb.propTypes = {
  name: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  name: selectedScheduleName(state),
});

export default connect(mapStateToProps)(OperatingHoursBreadcrumb);
