import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'semantic-ui-react/dist/es';

const NavBarChildren = ({ children }) => (
  <Container style={{ paddingTop: '5em' }}>{children}</Container>
);

NavBarChildren.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NavBarChildren;
