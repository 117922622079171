import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router-dom';

import LocationGroupsPage from './pages/LocationGroupsPage';
import LocationGroupEditPage from './pages/LocationGroupEditPage';

const LocationGroups = ({ match }) => (
  <Switch>
    <Route path={`${match.url}`} exact component={LocationGroupsPage} />
    <Route path={`${match.url}/create`} exact component={LocationGroupEditPage} />
    <Route path={`${match.url}/:id`} exact component={LocationGroupEditPage} />
  </Switch>
);

LocationGroups.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(LocationGroups);
