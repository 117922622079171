import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Grid } from 'semantic-ui-react/dist/es';

import {
  fetchLocationRequest,
  updateLocationRequest,
  updateLocationImageRequest,
  toggleLocationRequest,
  geocodeRequest,
  updateAddressRequest,
} from '@/features/locations/actions';
import LocationEditForm from '@/features/locations/LocationEditForm';
import LocationAddressForm from '@/features/locations/LocationAddressForm';
import StatusToggle from '@/common/components/StatusToggle';
import ImageUpload from '@/common/components/ImageUpload';

class LocationEditPage extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string,
      }).isRequired,
    }).isRequired,
    loading: PropTypes.bool.isRequired,
    location: PropTypes.shape().isRequired,
    fetchLocationRequest: PropTypes.func.isRequired,
    updateLocationRequest: PropTypes.func.isRequired,
    updateLocationImageRequest: PropTypes.func.isRequired,
    toggleLocationRequest: PropTypes.func.isRequired,
    geocodeRequest: PropTypes.func.isRequired,
    updateAddressRequest: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.fetchLocationRequest(id);
  }

  handleUpdate = (location) => {
    const { id } = this.props.match.params;
    this.props.updateLocationRequest(id, location);
  };

  handleUpload = (files) => {
    const { id } = this.props.match.params;
    this.props.updateLocationImageRequest(id, files);
  };

  handleStatusToggle = () => {
    const { id } = this.props.match.params;
    const { location } = this.props;
    const enabled = !location.enabled;

    this.props.toggleLocationRequest(id, enabled);
  };

  handleAddressUpdate = ({
    address1,
    address2,
    city,
    state,
    zipCode,
    geoLocationLookupAddress,
    latitude,
    longitude,
  }) => {
    const { id } = this.props.match.params;
    this.props.updateAddressRequest(
      id,
      address1,
      address2,
      city,
      state,
      zipCode,
      geoLocationLookupAddress,
      latitude,
      longitude,
    );
  };

  render() {
    const { loading, location, match } = this.props;

    return (
      <Grid stackable columns="equal">
        <Grid.Column width={12}>
          <LocationEditForm
            {...location}
            isSubmitting={loading}
            onSubmit={this.handleUpdate}
          />
          <LocationAddressForm
            latitude={location.latitude}
            longitude={location.longitude}
            address1={location.address1}
            address2={location.address2}
            city={location.city}
            state={location.state}
            zipCode={location.zipCode}
            geoLocationLookupAddress={location.geoLocationLookupAddress}
            isSubmitting={loading}
            handleGeocode={this.props.geocodeRequest}
            onSubmit={this.handleAddressUpdate}
          />
        </Grid.Column>
        <Grid.Column>
          <StatusToggle
            enabled={location.enabled}
            loading={loading}
            onClick={this.handleStatusToggle}
          />
          <ImageUpload
            onUpload={this.handleUpload}
            loading={loading}
            image={location.imageName}
          />
          <Button
            as={NavLink}
            to={`${match.url}/hours`}
            loading={loading}
            disabled={loading}
            fluid
            color="teal"
            icon="calendar"
            content="Manage Hours of Operation"
          />
        </Grid.Column>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.location.requesting,
  location: state.location.selectedLocation,
});

const actions = {
  fetchLocationRequest,
  updateLocationRequest,
  updateLocationImageRequest,
  toggleLocationRequest,
  geocodeRequest,
  updateAddressRequest,
};

export default withRouter(connect(mapStateToProps, actions)(LocationEditPage));
