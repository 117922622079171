import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Grid } from 'semantic-ui-react/dist/es';
import { history } from '@/app/store';

import api from '@/common/api';
import StatusToggle from '@/common/components/StatusToggle';
import ImageUpload from '@/common/components/ImageUpload';
import FeatureEditForm from '@/features/features/FeatureEditForm';

const UPDATE_SUCCESS = 'Successfully updated feature!';
const UPDATE_ERROR = 'Error updating feature!';

class FeatureEditPage extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  };

  state = {
    loading: true,
    feature: {},
    priorities: [],
  };

  async componentDidMount() {
    const { id } = this.props.match.params;

    try {
      let feature = {};
      if (id) {
        ({ data: feature } = await api.get(`/api/feature/${id}`));
      }

      const { data: priorities } = await api.get('/api/featurepriority');

      this.setState({
        loading: false,
        feature,
        priorities: priorities.map(p => ({
          value: p.id,
          text: p.name,
        })),
      });
    } catch (e) {
      toast.error(UPDATE_ERROR);
    }
  }

  handleSubmit = (feature) => {
    const { id } = this.props.match.params;

    this.setState({ loading: true }, async () => {
      try {
        const promise = id ?
          api.put(`/api/feature/${id}`, feature)
          : api.post('/api/feature', feature);

        const response = await promise;
        toast.success(UPDATE_SUCCESS);
        if (!id) {
          history.push(`/features/${response.data.id}`);
        }
      } catch (e) {
        toast.error(UPDATE_ERROR);
      }

      this.setState({ loading: false });
    });
  };

  handleStatusToggle = () => {
    const { id } = this.props.match.params;
    const url = `/api/feature/${id}/status`;

    this.setState({ loading: true }, async () => {
      const enabled = !this.state.feature.enabled;
      const promise = enabled ? api.put(url) : api.delete(url);
      try {
        await promise;
        toast.success(UPDATE_SUCCESS);
        this.setState(prevState => ({
          feature: {
            ...prevState.feature,
            enabled,
          },
        }));
      } catch (e) {
        toast.error(UPDATE_ERROR);
      }

      this.setState({ loading: false });
    });
  };

  handleUpload = async (files) => {
    if (!(files && files.length)) {
      toast.error('Unable to upload selected file. Is it in the correct format?');
      return;
    }

    this.setState({ loading: true }, async () => {
      const { id } = this.props.match.params;
      const file = files[0];
      const form = new FormData();
      form.append('file', file, file.name);

      try {
        const { data } = await api.put(`/api/feature/${id}/image`, form, {
          headers: { 'content-type': 'multipart/form-data' },
        });

        toast.success(UPDATE_SUCCESS);
        this.setState({
          feature: {
            ...this.state.feature,
            imageName: data,
          },
        });
      } catch (e) {
        toast.error(UPDATE_ERROR);
      }

      this.setState({ loading: false });
    });
  };

  render() {
    const { id } = this.props.match.params;

    return (
      <Grid stackable columns="equal">
        <Grid.Column>
          <FeatureEditForm
            {...this.state.feature}
            priorities={this.state.priorities}
            onSubmit={this.handleSubmit}
            isSubmitting={this.state.loading}
          />
        </Grid.Column>
        { id && (
          <Grid.Column width={4}>
            <StatusToggle
              enabled={this.state.feature.enabled}
              loading={this.state.loading}
              onClick={this.handleStatusToggle}
            />
            <ImageUpload
              onUpload={this.handleUpload}
              loading={this.state.loading}
              image={this.state.feature.imageName}
            />
          </Grid.Column>
        )}
      </Grid>
    );
  }
}

export default withRouter(FeatureEditPage);
