import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Yup from 'yup';
import { Button, Divider, Form, Header, Message, Segment } from 'semantic-ui-react/dist/es';
import { SingleDatePicker } from 'react-dates';
import TimePicker from '@/common/components/TimePicker';
import {
  getMomentFromDateString,
  getTimeFromDateString,
  getMeridiemFromDateString,
  getIsoFormattedString,
  DEFAULT_START_TIME,
  DEFAULT_START_MERIDIEM,
  DEFAULT_END_TIME,
  DEFAULT_END_MERIDIEM,
} from '@/common/utils/date-utils';

const FeatureEditForm = ({
  name,
  startDate,
  endDate,
  priorityId,
  maxViews,
  url,
  onSubmit,
  isSubmitting,
  priorities,
}) => {
  const startMoment = getMomentFromDateString(startDate);
  const startTime = getTimeFromDateString(startDate);
  const startMeridiem = getMeridiemFromDateString(startDate);

  const endMoment = getMomentFromDateString(endDate);
  const endTime = getTimeFromDateString(endDate);
  const endMeridiem = getMeridiemFromDateString(endDate);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: name || '',
        maxViews: maxViews || 0,
        priorityId,
        url: url || '',
        startMoment,
        startTime: startTime || DEFAULT_START_TIME,
        startMeridiem: startMeridiem || DEFAULT_START_MERIDIEM,
        endMoment,
        endTime: endTime || DEFAULT_END_TIME,
        endMeridiem: endMeridiem || DEFAULT_END_MERIDIEM,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .required('Name is required!')
          .max(100, 'Name must not exceed 100 characters!'),
        startMoment: Yup.date()
          .typeError('Start date is required!')
          .required('Start date is required!'),
        startTime: Yup.string().required('Start time is required!'),
        endMoment: Yup.date()
          .typeError('End date is required!')
          .required('End date is required!'),
        url: Yup.string()
          .max(500, 'Url must not exceed 500 characters!')
          .url('Url must be valid!'),
        endTime: Yup.string().required('End time is required!'),
        priorityId: Yup.number().required('Priority is required!'),
        maxViews: Yup.number().required('Maximum # of views is required!'),
      })}
      onSubmit={(values) => {
        onSubmit({
          ...values,
          startDate: getIsoFormattedString(
            values.startMoment,
            values.startTime,
            values.startMeridiem,
          ),
          endDate: getIsoFormattedString(values.endMoment, values.endTime, values.endMeridiem),
        });
      }}
      render={({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isValid,
        setFieldValue,
        setStatus,
        status,
      }) => (
        <Segment clearing>
          <Header size="medium">Feature</Header>

          <Form onSubmit={handleSubmit} error={!!errors} loading={isSubmitting}>
            <Form.Group widths="equal">
              <Form.Input
                name="name"
                label="Name"
                placeholder="Name"
                value={values.name}
                error={touched.name && !!errors.name}
                onBlur={handleBlur}
                onChange={handleChange}
                required
              />

              <Form.Input
                name="url"
                label="Url"
                placeholder="Url"
                value={values.url}
                error={touched.url && !!errors.url}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </Form.Group>

            {errors &&
              errors.name &&
              touched.name && <Message negative content={errors.name} size="mini" />}

            {errors &&
              errors.url &&
              touched.url && <Message negative content={errors.url} size="mini" />}

            <Form.Dropdown
              selection
              name="priorityId"
              label="Priority"
              placeholder="Select Priority"
              options={priorities}
              value={values.priorityId}
              error={touched.priorityId && !!errors.priorityId}
              onChange={(e, { value }) => setFieldValue('priorityId', value)}
              required
            />

            {errors &&
              errors.priorityId &&
              touched.priorityId && <Message negative content={errors.priorityId} size="mini" />}

            <Form.Input
              name="maxViews"
              label="Maximum # of Views"
              placeholder="Maximum # of Views"
              value={values.maxViews}
              error={touched.maxViews && !!errors.maxViews}
              onBlur={handleBlur}
              onChange={handleChange}
              required
              type="number"
            />

            {/* eslint-disable jsx-a11y/label-has-for */}
            <Form.Group unstackable>
              <Form.Field required>
                <label>Start Date</label>

                <SingleDatePicker
                  id="start-date-picker"
                  date={values.startMoment}
                  numberOfMonths={1}
                  onDateChange={date => setFieldValue('startMoment', date)}
                  focused={status && status.startDateFocused}
                  onFocusChange={({ focused }) =>
                    setStatus({ ...status, startDateFocused: focused })
                  }
                />
              </Form.Field>

              <Form.Field required>
                <label>Start Time</label>
                <TimePicker
                  id="start-time-picker"
                  onFocusChange={focused => setStatus({ ...status, startTimeFocused: focused })}
                  focused={status && status.startTimeFocused}
                  meridiem={values.startMeridiem}
                  time={values.startTime}
                  onTimeChange={({ hour, minute, meridiem }) => {
                    setFieldValue('startTime', `${hour}:${minute.includes('-') ? 60 - minute.split('-')[1] : minute}`);
                    setFieldValue('startMeridiem', meridiem);
                  }}
                />
              </Form.Field>
            </Form.Group>

            {errors &&
              errors.startMoment &&
              touched.startMoment && (
                <Message negative content={errors.startMoment} size="mini" />
              )}

            {/* eslint-disable jsx-a11y/label-has-for */}
            <Form.Group unstackable>
              <Form.Field required>
                <label>End Date</label>

                <SingleDatePicker
                  id="end-date-picker"
                  date={values.endMoment}
                  numberOfMonths={1}
                  onDateChange={date => setFieldValue('endMoment', date)}
                  focused={status && status.endDateFocused}
                  onFocusChange={({ focused }) => setStatus({ ...status, endDateFocused: focused })}
                />
              </Form.Field>

              <Form.Field required>
                <label>End Time</label>
                <TimePicker
                  id="end-time-picker"
                  onFocusChange={focused => setStatus({ ...status, endTimeFocused: focused })}
                  focused={status && status.endTimeFocused}
                  meridiem={values.endMeridiem}
                  time={values.endTime}
                  onTimeChange={({ hour, minute, meridiem }) => {
                    setFieldValue('endTime', `${hour}:${minute.includes('-') ? 60 - minute.split('-')[1] : minute}`);
                    setFieldValue('endMeridiem', meridiem);
                  }}
                />
              </Form.Field>
            </Form.Group>

            {errors &&
              errors.endMoment &&
              touched.endMoment && (
                <Message negative content={errors.endMoment} size="mini" />
              )}

            <Divider />

            <Button color="teal" disabled={isSubmitting || !isValid} floated="right">
              Save
            </Button>
          </Form>
        </Segment>
      )}
    />
  );
};

FeatureEditForm.propTypes = {
  name: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  maxViews: PropTypes.number,
  url: PropTypes.string,
  priorityId: PropTypes.number,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  priorities: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })),
};

FeatureEditForm.defaultProps = {
  name: '',
  maxViews: 0,
  priorityId: null,
  startDate: null,
  endDate: null,
  priorities: [],
  url: '',
};

export default FeatureEditForm;
