import React from 'react';
import PropTypes from 'prop-types';
import { Input, Menu } from 'semantic-ui-react/dist/es';

const SearchMenu = ({ onSearchChange, children }) => (
  <Menu stackable borderless secondary>
    {children}
    <Menu.Item position="right">
      <Input
        className="icon"
        icon="search"
        placeholder="Search..."
        onChange={onSearchChange}
      />
    </Menu.Item>
  </Menu>
);

SearchMenu.propTypes = {
  onSearchChange: PropTypes.func.isRequired,
  children: PropTypes.node,
};

SearchMenu.defaultProps = {
  children: null,
};

export default SearchMenu;
