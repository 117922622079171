import React from 'react';
import { Grid } from 'semantic-ui-react/dist/es';

import FranchiseEditFormContainer from '@/features/franchise/FranchiseEditForm/FranchiseEditFormContainer';
import MenuUploadContainer from './MenuUpload/MenuUploadContainer';

const Franchise = () => (
  <Grid stackable columns="equal">
    <Grid.Column width={12}>
      <FranchiseEditFormContainer />
    </Grid.Column>
    <Grid.Column>
      <MenuUploadContainer />
    </Grid.Column>
  </Grid>
);

export default Franchise;
