import React from 'react';
import PropTypes from 'prop-types';
import Lightbox from 'react-images';
import { Image } from 'semantic-ui-react/dist/es';

import { getImageUrl, getThumbnailUrl } from '@/common/utils/image-utils';

class ImagePreview extends React.Component {
  state = {
    lightboxIsOpen: false,
  };

  openLightbox = (event) => {
    event.preventDefault();
    this.setState({
      lightboxIsOpen: true,
    });
  };

  closeLightbox = () => {
    this.setState({
      lightboxIsOpen: false,
    });
  };

  renderGallery() {
    return (
      <div style={{ paddingBottom: '5px' }}>
        <Image
          bordered
          centered
          fluid
          rounded
          src={getThumbnailUrl(this.props.image)}
          onClick={e => this.openLightbox(e)}
        />
      </div>
    );
  }

  renderLightbox() {
    const images = [{ src: getImageUrl(this.props.image) }];

    return (
      <Lightbox
        showImageCount={false}
        images={images}
        isOpen={this.state.lightboxIsOpen}
        onClose={this.closeLightbox}
        backdropClosesModal
      />
    );
  }

  render() {
    const { image } = this.props;
    if (!image) {
      return null;
    }

    return (
      <React.Fragment>
        {this.renderGallery()}
        {this.renderLightbox()}
      </React.Fragment>
    );
  }
}

ImagePreview.propTypes = {
  image: PropTypes.string,
};

ImagePreview.defaultProps = {
  image: '',
};

export default ImagePreview;
