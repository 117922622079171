import React from 'react';
import { Grid, Image } from 'semantic-ui-react/dist/es';

import emptyImage from '@/common/assets/no-items.png';

const NoItems = () => (
  <Grid textAlign="center" verticalAlign="middle">
    <Grid.Column style={{ maxWidth: 450 }}>
      <Image src={emptyImage} />
    </Grid.Column>
  </Grid>
);

export default NoItems;
