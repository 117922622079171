import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CallbackComponent } from 'redux-oidc';
import { push } from 'react-router-redux';
import { Grid, Loader } from 'semantic-ui-react/dist/es';
import userManager from '@/common/utils/user-manager';

const Callback = ({ dispatch }) => (
  <CallbackComponent
    userManager={userManager}
    successCallback={() => dispatch(push('/'))}
    errorCallback={() => dispatch(push('/'))}
  >
    <Grid textAlign="center" style={{ height: '100vh' }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Loader active size="massive">
          Logging in...
        </Loader>
      </Grid.Column>
    </Grid>
  </CallbackComponent>
);

Callback.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect()(Callback);
