import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router-dom';

import LocationBreadcrumb from '@/features/locations/LocationBreadcrumb';
import OperatingHoursPage from '@/features/hours-of-operation/pages/OperatingHoursPage';
import OperatingHoursEditPage from '@/features/hours-of-operation/pages/OperatingHoursEditPage';
import OperatingHoursCreatePage from '@/features/hours-of-operation/pages/OperatingHoursCreatePage';
import LocationEditPage from './LocationEditPage';

const LocationPage = ({ match }) => (
  <React.Fragment>
    <LocationBreadcrumb />

    <Switch>
      <Route path={`${match.path}/hours`} exact component={OperatingHoursPage} />
      <Route path={`${match.path}/hours/create`} exact component={OperatingHoursCreatePage} />
      <Route path={`${match.path}/hours/:hoursId`} exact component={OperatingHoursEditPage} />
      <Route path={match.path} exact component={LocationEditPage} />
    </Switch>
  </React.Fragment>
);

LocationPage.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(LocationPage);
