import React from 'react';
import PropTypes from 'prop-types';
import { Header, Message, Segment } from 'semantic-ui-react/dist/es';

import FileUpload from '@/common/components/FileUpload';

const CONTENT_TYPES = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

/* eslint-disable no-unused-vars */
const MenuUpload = ({ lastSuccessfulUpload, onUpload }) => (
  <Segment>
    <Header>Menu Upload</Header>
    {
      lastSuccessfulUpload &&
      <Message positive size="tiny">
        <Message.Header>Successfully Uploaded</Message.Header>
        <p>
          The menu was last successfully processed on
          <strong>{lastSuccessfulUpload.toLocateDateString()}</strong>.
        </p>
      </Message>
    }
    <FileUpload
      guidance="Click here to upload the menu file (.xlsx)"
      contentTypes={CONTENT_TYPES}
      onUpload={onUpload}
    />
  </Segment>
);

MenuUpload.propTypes = {
  lastSuccessfulUpload: PropTypes.instanceOf(Date),
  onUpload: PropTypes.func.isRequired,
};

MenuUpload.defaultProps = {
  lastSuccessfulUpload: null,
};

export default MenuUpload;
