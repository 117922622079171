import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react/dist/es';

const SuggestionItem = styled.div`
  padding: 4px;
  text-align: left;
`;

const LocationPickerSuggestion = ({ formattedSuggestion }) => (
  <SuggestionItem>
    <Icon name="point" style={{ marginRight: '8px' }} />
    <strong>{formattedSuggestion.mainText}</strong>{' '}
    <small className="text-muted">{formattedSuggestion.secondaryText}</small>
  </SuggestionItem>
);

LocationPickerSuggestion.propTypes = {
  formattedSuggestion: PropTypes.shape({
    mainText: PropTypes.string.isRequired,
    secondaryText: PropTypes.string.isRequired,
  }).isRequired,
};

export default LocationPickerSuggestion;
