import { fork, call, put, take } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

import api from '@/common/api';
import {
  LOCATION_FETCHING_REQUEST,
  LOCATION_FETCHING_ERROR,
  LOCATION_UPDATING_REQUEST,
  LOCATION_UPDATING_ERROR,
  LOCATION_UPDATING_SUCCESS,
  LOCATION_IMAGE_UPLOAD_REQUEST,
  LOCATION_IMAGE_UPLOAD_ERROR,
  LOCATION_TOGGLE_REQUEST,
  LOCATION_TOGGLE_ERROR,
  LOCATION_TOGGLE_SUCCESS,
  // LOCATION_GEOCODE_REQUEST,
  GEOCODE_REQUEST,
  GEOCODE_ERROR,
  MSG_GEOCODE_ERROR,
  // LOCATION_GEOCODE_ERROR,
  MSG_IMAGE_UPLOAD_ERROR,
  MSG_IMAGE_UPLOAD_INVALID,
  MSG_IMAGE_UPLOAD_SUCCESS,
  MSG_FETCH_ERROR,
  MSG_UPDATE_ERROR,
  MSG_UPDATE_SUCCESS,
  MSG_TOGGLE_ERROR,
  MSG_TOGGLE_SUCCESS,
  MSG_GEOCODE_SUCCESS,
  LOCATION_ADDRESS_UPDATE_REQUEST,
  LOCATION_ADDRESS_UPDATE_SUCCESS,
  LOCATION_ADDRESS_UPDATE_ERROR,
  LOCATION_ADDRESS_UPDATE_SUCCESS_MESSAGE,
  LOCATION_ADDRESS_UPDATE_ERROR_MESSAGE,
} from './constants';

import {
  fetchLocationSuccess,
  updateLocationImageSuccess,
  geocodeSuccess,
} from './actions';

function getLocation(id) {
  return api.get(`/api/location/${id}`);
}

function* locationRequestWatcher() {
  while (true) {
    const { payload } = yield take(LOCATION_FETCHING_REQUEST);

    try {
      const { data: location } = yield call(getLocation, payload.id);

      yield put(fetchLocationSuccess(location));
    } catch (error) {
      yield put({ type: LOCATION_FETCHING_ERROR, error });
      yield call(toast.error, MSG_FETCH_ERROR);
    }
  }
}

function updateLocation(id, location) {
  return api.put(`/api/location/${id}`, location);
}

function* locationUpdateWatcher() {
  while (true) {
    const { payload } = yield take(LOCATION_UPDATING_REQUEST);

    try {
      const { id, location } = payload;
      yield call(updateLocation, id, location);
      yield put({ type: LOCATION_UPDATING_SUCCESS });
      yield call(toast.success, MSG_UPDATE_SUCCESS);
    } catch (error) {
      yield put({ type: LOCATION_UPDATING_ERROR, error });
      yield call(toast.error, MSG_UPDATE_ERROR);
    }
  }
}

function updateLocationImage(id, file) {
  const form = new FormData();
  form.append('file', file, file.name);

  return api.put(`/api/location/${id}/image`, form, {
    headers: { 'content-type': 'multipart/form-data' },
  });
}

function validateLocationImages(files) {
  if (files && files.length) {
    return true;
  }

  return false;
}

function* locationImageUploadWatcher() {
  while (true) {
    const { payload } = yield take(LOCATION_IMAGE_UPLOAD_REQUEST);

    try {
      const { id, files } = payload;
      const isValid = yield call(validateLocationImages, files);
      if (!isValid) {
        yield call(toast.error, MSG_IMAGE_UPLOAD_INVALID);
        return;
      }

      const { data: imageName } = yield call(updateLocationImage, id, files[0]);
      yield put(updateLocationImageSuccess(imageName));
      yield call(toast.success, MSG_IMAGE_UPLOAD_SUCCESS);
    } catch (error) {
      yield put({ type: LOCATION_IMAGE_UPLOAD_ERROR, error });
      yield call(toast.success, MSG_IMAGE_UPLOAD_ERROR);
    }
  }
}

function toggleStatus(id, enabled) {
  const url = `/api/location/${id}/status`;
  const promise = enabled ? api.put(url) : api.delete(url);
  return promise;
}

function* locationStatusToggleWatcher() {
  while (true) {
    const { payload } = yield take(LOCATION_TOGGLE_REQUEST);

    try {
      const { id, enabled } = payload;

      yield call(toggleStatus, id, enabled);
      yield put({ type: LOCATION_TOGGLE_SUCCESS });
      yield call(toast.success, MSG_TOGGLE_SUCCESS);
    } catch (error) {
      yield put({ type: LOCATION_TOGGLE_ERROR, error });
      yield call(toast.success, MSG_TOGGLE_ERROR);
    }
  }
}

function* geocodeWatcher() {
  while (true) {
    const { payload } = yield take(GEOCODE_REQUEST);

    try {
      const { address } = payload;
      const results = yield call(geocodeByAddress, address);
      const { lat: latitude, lng: longitude } = yield call(getLatLng, results[0]);

      yield put(geocodeSuccess(address, latitude, longitude));
      yield call(toast.success, MSG_GEOCODE_SUCCESS);
    } catch (error) {
      yield put({ type: GEOCODE_ERROR, error });
      yield call(toast.error, MSG_GEOCODE_ERROR);
    }
  }
}

function updateAddress(id, address) {
  return api.put(`/api/location/${id}/address`, address);
}

function* addressUpdateWatcher() {
  while (true) {
    const { payload } = yield take(LOCATION_ADDRESS_UPDATE_REQUEST);

    try {
      const { id, ...address } = payload;
      yield call(updateAddress, id, address);
      yield put({ type: LOCATION_ADDRESS_UPDATE_SUCCESS });
      yield call(toast.success, LOCATION_ADDRESS_UPDATE_SUCCESS_MESSAGE);
    } catch (error) {
      yield put({ type: LOCATION_ADDRESS_UPDATE_ERROR, error });
      yield call(toast.error, LOCATION_ADDRESS_UPDATE_ERROR_MESSAGE);
    }
  }
}

export default function* root() {
  yield fork(locationRequestWatcher);
  yield fork(locationUpdateWatcher);
  yield fork(locationImageUploadWatcher);
  yield fork(locationStatusToggleWatcher);
  yield fork(geocodeWatcher);
  yield fork(addressUpdateWatcher);
}
