import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Breadcrumb, Segment } from 'semantic-ui-react';

const NavBreadcrumb = ({ breadcrumbs }) => (
  <Segment>
    <Breadcrumb>
      {breadcrumbs.map((breadcrumb, index) => (
        <Fragment key={breadcrumb.props.key}>
          {index < breadcrumbs.length - 1 &&
            <Breadcrumb.Section link as={NavLink} to={breadcrumb.props.match.url}>
              {breadcrumb}
            </Breadcrumb.Section>
          }
          {index === breadcrumbs.length - 1 &&
            <Breadcrumb.Section active>{breadcrumb}</Breadcrumb.Section>
          }
          {index < breadcrumbs.length - 1 && <Breadcrumb.Divider />}
        </Fragment>
      ))}
    </Breadcrumb>
  </Segment>
);

NavBreadcrumb.propTypes = {
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape({
    breadcrumb: PropTypes.node,
  })),
};

NavBreadcrumb.defaultProps = {
  breadcrumbs: [],
};

export default NavBreadcrumb;
